import * as types from "./types";
import * as API_URL from "../../../env";

export const listRoles = (payload) => ({
    type: types.FETCH_ROLE_LIST,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/role/list-roles",
        method: "POST",
        body: payload
    },
});