import React from 'react';

class Logout extends React.Component {
    componentDidMount() {
        this.logoutHandler();
    }

    logoutHandler = async () => {
        localStorage.removeItem('token');
        localStorage.removeItem('authenticated');
        localStorage.removeItem('default_color');
        localStorage.removeItem('secondary_color');
        localStorage.removeItem('sidebar_layout');
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('roles');
        localStorage.removeItem('selectedUserId');
        localStorage.removeItem('selectedVehicleId');
        localStorage.removeItem('selectedEmail');
        localStorage.removeItem('states');
        localStorage.removeItem('cities');
        localStorage.removeItem('dns');
        localStorage.removeItem('parkingPlazaInformation');
        localStorage.removeItem('gateInformation');
        localStorage.removeItem('TariffCollectionAuthorityInfo');
        localStorage.removeItem('serverInformation');
        localStorage.removeItem('selectedPlazaId');
        localStorage.removeItem('plaza');
        localStorage.removeItem('floor');
        localStorage.removeItem('selectedGateId');
        localStorage.removeItem('selectedLaneId');
        localStorage.removeItem('selectedGateLaneId');
        localStorage.removeItem('gate');
        localStorage.removeItem('menus');
        window.open("/login/","_self");
    };
    render() {
        return (<span></span>);
    }
}

export default Logout;