import * as moment from 'moment';

export function formatDate(yDate) {
    const formattedDate = moment(yDate).format("YYYY-MM-DD")
    return formattedDate;
}

export function formatDateAndTime(yDate) {
    const date = new Date(yDate);
    const options = { 
      year: 'numeric', 
      month: 'numeric', 
      day: 'numeric', 
      hour: 'numeric', 
      minute: 'numeric', 
      second: 'numeric',
      hour12: false,
      timeZone: 'UTC'
    };
    const formattedDate = date.toLocaleString('en-US', options);
    return formattedDate;
}

export function filterStatesByCountry(countryId) {
    let states = localStorage.getItem('states');
    states = JSON.parse(states);
    let finalData = states.filter(data => Number(countryId) === data.country_id);
    return finalData;
}

export function filterCitiesByState(stateId) {
    let cities = localStorage.getItem('cities');
    cities = JSON.parse(cities);
    let finalData = cities.filter(data => Number(stateId) === data.state_id);
    return finalData;
}

export function getRoleName(id) {
    let name = '';
    let roles = localStorage.getItem('roles');
    if (roles) {
        roles = JSON.parse(roles);
        let foundData = roles.find(data => data.id === id);
        if (foundData) {
            name = foundData.role_name;
        }
    }
    return name;
}

export function getParkingPlazaName(id) {
    let name = '';
    let plaza = localStorage.getItem('plaza');
    if (plaza) {
        plaza = JSON.parse(plaza);
        let foundData = plaza.find(data => data.id === id);
        if (foundData) {
            name = foundData.p_name;
        }
    }
    return name;
}