import * as types from "./types";
import * as API_URL from "../../../env";

export const listCountry = (payload) => ({
    type: types.FETCH_LIST,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/master/list-country",
        method: "POST",
        body: payload
    },
});

export const listState = (payload) => ({
    type: types.FETCH_LIST,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/master/list-state",
        method: "POST",
        body: payload
    },
});

export const listCity = (payload) => ({
    type: types.FETCH_LIST,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/master/list-city",
        method: "POST",
        body: payload
    },
});

export const listDNS = (payload) => ({
    type: types.FETCH_LIST,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/master/dns-list",
        method: "POST",
        body: payload
    },
});

export const floorList = (payload) => ({
    type: types.FETCH_LIST,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/master/floor-list",
        method: "POST",
        body: payload
    },
});