import React, { Fragment, Component } from "react";
import { Container, Card, CardBody, CardHeader, Col, Row, Form } from 'reactstrap';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Charts from 'react-apexcharts';
import "chart.js/auto";
import { Doughnut } from 'react-chartjs-2';
import SvgIcon from '../../../Components/Common/Component/SvgIcon';
import dollarIcon from '../.././../assets/images/dashboard/dollar.png';
import disputeIcon from '../.././../assets/images/dashboard/dispute.png';
import { H3, H6, H5, Btn } from '../../../AbstractElements';
import { MarketChart } from '../../../Components/Common/Data/ApexChart';
import { DailyDropdown } from '../../../Constant';
import DropdownCommon from '../../../Components/Common/Dropdown';

class Dashboard extends Component {
  
  render() {
    const data1 = {
      datasets: [
        {
          data: [70, 30],
          backgroundColor: ['#00ccff', '#99ebff'],
          hoverBackgroundColor: ['#00ccff', '#99ebff'],
          borderColor: ['#00ccff', '#99ebff'],
          circumference: 180,
          rotation: 270,
        },
      ],
    };

    const data2 = {
      datasets: [
        {
          data: [70, 30],
          backgroundColor: ['#ff8080', '#ffb3b3'],
          hoverBackgroundColor: ['#ff8080', '#ffb3b3'],
          borderColor: ['#ff8080', '#ffb3b3'],
          circumference: 180,
          rotation: 270,
        },
      ],
    };

    const options = {
      plugins: {
        doughnutLabel: {
          labels: [
            {
              text: '70% Complete',
              font: {
                size: '20',
                weight: 'bold',
                color: '#000'
              },
            },
          ],
        },
      },
    };

    const buttionStyle =  {
      "border": "none", 
      "borderRadius": "3px",
      "padding": "5px",
      "background": "#ccf5ff",
      "color": "#008fb3", 
      "fontSize": "12px"
    }
    const editBtnStyle =  {
      "border": "none", 
      "borderRadius": "3px",
      "background": "#e6e6ff",
      "color": "#000099", 
      "fontSize": "10px"
    }
    const boxStyle = { 
      "color": "#000099", 
      "boxShadow": "1px 5px 5px #000099", 
      "border": "1px solid #000099"
    }
    return (
      <Fragment>
        <div className='page-title'>
          <Row>
            <Col xs='6'>
              <H3>Dashboard</H3>
            </Col>
            <Col xs='6'>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <Link to="/dashboard/">
                    <SvgIcon iconId='stroke-home' />
                  </Link>
                </li>
                <li className='breadcrumb-item active'>Dashboard</li>
              </ol>
            </Col>
          </Row>
        </div>
        <Container fluid={true}>
          <Row className="widget-grid">
            <Col md='6'>
              <Card>
                <CardHeader className='d-flex align-items-center justify-content-between'>
                  <H6>Overview</H6>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md='6'>
                      <Card className="small-widget" style={boxStyle}>
                        <CardBody>
                          <span className='f-light'>Transaction Amount</span>
                          <H5>1,75,23,470</H5>
                          <div className='bg-gradient mt-1'>
                            <img src={dollarIcon} alt="dollar" height={30} width={30} />
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md='6'>
                      <Card className="small-widget" style={boxStyle}>
                        <CardBody>
                          <span className='f-light'>Expenses</span>
                          <H5>1,75,23,470</H5>
                          <div className='bg-gradient mt-1'>
                            <img src={dollarIcon} alt="dollar" height={30} width={30} />
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col md='6'>
                      <Card className="small-widget" style={boxStyle}>
                        <CardBody>
                          <span className='f-light'>Enter Vehicle Count</span>
                          <H5>$22k</H5>
                          <div className='bg-gradient mt-1'>
                            <img src={dollarIcon} alt="dollar" height={30} width={30} />
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md='6'>
                      <Card className="small-widget" style={boxStyle}>
                        <CardBody>
                          <span className='f-light'>Dispute Count</span>
                          <H5>1,75,23,470</H5>
                          <div className='bg-gradient mt-1'>
                            <img src={disputeIcon} alt="dollar" height={30} width={30} />
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md='6'>
              <Card>
                <CardHeader className='d-flex align-items-center justify-content-between'>
                  <H6></H6>
                  <button type='button' style={buttionStyle}>View Detail</button>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md='6'>
                      <Card style={{ "background": "#ccf5ff" }}>
                        <CardHeader className='card-no-border' style={{ "background": "#ccf5ff" }}>
                          <div className='header-top gap-1'>
                            <H6>System Health</H6>
                          </div>
                        </CardHeader>
                        <CardBody className='pt-0'>
                          <Form className='theme-form crypto-form'>
                            <Doughnut style={{ "marginTop": "-47px", "marginBottom": "-65px" }} data={data1} options={options} />
                            <p style={{ "textAlign": "center", "fontSize": "10px", "marginBottom": "-5px" }}>70%</p>
                            <p style={{ "textAlign": "center", "fontSize": "10px" }}>Completed</p>
                            <h6 style={{ "textAlign": "center", "marginTop": "-5px", "marginBottom": "6px" }} >70% Accuracy</h6>
                            <Btn attrBtn={{ type: 'submit', color: 'primary', className: 'btn-hover-effect w-100' }}>View Report</Btn>
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md='6'>
                      <Card style={{ "background": "#ffe6e6" }}>
                        <CardHeader className='card-no-border' style={{ "background": "#ffe6e6" }}>
                          <div className='header-top gap-1'>
                            <H6>Failure Scenarios</H6>
                          </div>
                        </CardHeader>
                        <CardBody className='pt-0'>
                        <Form className='theme-form crypto-form'>
                            <Doughnut style={{ "marginTop": "-47px", "marginBottom": "-65px" }} data={data2} options={options} />
                            <p style={{ "textAlign": "center", "fontSize": "10px", "marginBottom": "-5px" }}>8%</p>
                            <p style={{ "textAlign": "center", "fontSize": "10px" }}>Completed</p>
                            <h6 style={{ "textAlign": "center", "marginTop": "-5px", "marginBottom": "6px" }} >10/Month</h6>
                            <Btn attrBtn={{ type: 'submit', color: 'primary', className: 'btn-hover-effect w-100' }}>View Report</Btn>
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md="12">
              <Card className='visitor-card'>
                <CardHeader className='card-no-border'>
                  <div className='header-top'>
                    <H6>Dispute Handling</H6>
                    <div className='card-header-right-icon'>
                      <DropdownCommon dropdownMain={{ className: 'icon-dropdown', direction: 'start' }} options={DailyDropdown} iconName='icon-more-alt' btn={{ tag: 'span' }} />
                    </div>
                  </div>
                </CardHeader>
                <CardBody className='pt-0'>
                  <div className='visitors-container'>
                    <Charts options={MarketChart.options} series={MarketChart.series} type='bar' height={360} />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xxl='5' lg='12' md='11' className='box-col-8 col-ed-6'>
              <Card>
                <CardHeader className='d-flex align-items-center justify-content-between'>
                  <H6>Real Time Data</H6>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row>
                      <Table striped hover responsive bordered id="data-table-zero">
                        <thead>
                          <tr>
                            <th>Record Number</th>
                            <th>Vehicle ID</th>
                            <th>Vehicle Number</th>
                            <th>Entry Date & Time</th>
                            <th>Exit Date & Time</th>
                            <th>Location</th>
                            <th>Tariff Amount</th>
                            <th>Method of Payment</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>984W</td>
                            <td>3</td>
                            <td>Car</td>
                            <td>345789675644545</td>
                            <td>GJ 012342</td>
                            <td>231</td>
                            <td>12312312312312312</td>
                            <td><button style={editBtnStyle} >Initiate</button></td>
                          </tr>
                          <tr>
                            <td>984W</td>
                            <td>3</td>
                            <td>Car</td>
                            <td>345789675644545</td>
                            <td>GJ 012342</td>
                            <td>231</td>
                            <td>12312312312312312</td>
                            <td><button style={editBtnStyle} >Initiate</button></td>
                          </tr>
                          <tr>
                            <td>984W</td>
                            <td>3</td>
                            <td>Car</td>
                            <td>345789675644545</td>
                            <td>GJ 012342</td>
                            <td>231</td>
                            <td>12312312312312312</td>
                            <td><button style={editBtnStyle} >Initiate</button></td>
                          </tr>
                        </tbody>
                      </Table>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
};

export default Dashboard;