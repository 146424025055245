import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Search } from 'react-feather';
import { Button, Table } from 'react-bootstrap';
import { Container, Row, Col, Card, CardBody, CardHeader, Input, CardFooter, Form } from 'reactstrap';
import SvgIcon from '../../../Components/Common/Component/SvgIcon';
import { H3, H6 } from '../../../AbstractElements';
import { userOperations } from '../../../state/ducks/user';
import { roleOperations } from '../../../state/ducks/role';
import Pagination from '../../Pagination';
import '../../../assets/scss/pagination.css';

class RevenueReport extends React.Component {
    constructor() {
        super();
        this.state = {
            userData : [],
            roles: [],
            defaultSearchData: [],
            searchData: '',
            currentPage: 1,
            itemsPerPage: 10,
            sortedColumn: null,
            sortOrder: 'asc'
        }
    }
      
    async componentDidMount() {
        try {
            this.loadLocalStorageData();
            const response = await this.props.listUsers();
            this.setState({ userData: response.payload, defaultSearchData: response.payload });
        } catch (err) {
            console.log(err);
        }
    }
    
    loadLocalStorageData = async () => {
        try {
            let roles = localStorage.getItem('roles');
            if (roles) {
                roles = JSON.parse(roles);
                this.setState({ roles });
            } else {
                const response = await this.props.listRoles();
                if (response.payload.length !== 0) {
                    const rolesString = JSON.stringify(response.payload);
                    localStorage.setItem('roles', rolesString);
                    roles = JSON.parse(rolesString);
                    this.setState({ roles });
                }
            }
        } catch (error) {
            console.error("Error loading data:", error.message);
        }
    }

    onSearch = async () => { 
        try {
            const search = { role_id: this.state.roleId };
            const searchString = JSON.stringify(search);
            const options = { "search": searchString };
            let response = await this.props.listUsers(options);
            this.setState({ userData: response.payload, defaultSearchData: response.payload });
        } catch (err) {
            console.log("Error While Bill Listing"+err);
        }
    }

    onClear = async () => {        
        try {
            this.componentDidMount();
        } catch (err) {
            console.log("Error While Bill Listing"+err);
        }
    }

    handleChange = (e) => {
        try {
            let tid = e.target.id;
            switch (tid) {
                case 'roles':
                    this.setState({ roleId: e.target.value });
                    break;
                default:
                    console.log("No matching target found");
                    break;
            }
        } catch (err) {
            console.error(err.message);
        }
    }

    handlePageChange = (pageNumber) => {
        this.setState({ currentPage: pageNumber });
    };

    DefaultSearchChange = (e) => {
        const searchData = e.target.value;
        const defaultSearchData = this.state.userData.filter(data => {
        const valuesToSearch = Object.values(data).filter(value => value !== null);
            return valuesToSearch.some(value =>
                String(value).toLowerCase().includes(searchData.toLowerCase())
            );
        });
        this.setState({ searchData, defaultSearchData });
    };

    handleTableSort = (column) => {
        const { defaultSearchData, sortedColumn, sortOrder } = this.state;
        // Toggle the sortOrder if the same column is clicked again
        const newSortOrder = column === sortedColumn && sortOrder === 'asc' ? 'desc' : 'asc';
    
        // Sort the data based on the selected column and sortOrder
        const sortedData = defaultSearchData.slice().sort((a, b) => {
          if (newSortOrder === 'asc') {
            return a[column] > b[column] ? 1 : -1;
          } else {
            return a[column] < b[column] ? 1 : -1;
          }
        });
    
        this.setState({
            defaultSearchData: sortedData,
            sortedColumn: column,
            sortOrder: newSortOrder,
        });
    };

    render() {
        const { roles, defaultSearchData, currentPage, itemsPerPage, searchData, sortedColumn, sortOrder } = this.state;
        // For Pagination
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentData = defaultSearchData.slice(indexOfFirstItem, indexOfLastItem);
        const totalPages = Math.ceil(defaultSearchData.length / itemsPerPage);
        const inputFontStyle = { fontSize: '10px' };
        const editBtnStyle =  {
            "border": "none", 
            "borderRadius": "3px",
            "background": "#e6e6ff",
            "color": "#000099", 
            "fontSize": "10px"
        }
        const boxStyle = { 
            "color": "#000099", 
            "boxShadow": "1px 5px 5px #000099", 
            "border": "1px solid #000099"
        }
        return (
            <Fragment>
                <div className='page-title'>
                    <Row>
                        <Col xs='6'>
                            <H3></H3>
                        </Col>
                        <Col xs='6'>
                            <ol className='breadcrumb'>
                                <li className='breadcrumb-item'>
                                    <Link to="/dashboard/">
                                        <SvgIcon iconId='stroke-home' />
                                    </Link>
                                </li>
                                <li className='breadcrumb-item'>
                                    <Link style={{"color":"#000"}} to="/reports/">Report</Link>
                                </li>
                                <li className='breadcrumb-item active'>Revenue Report</li>
                            </ol>
                        </Col>
                    </Row>
                </div>
                <Container fluid={true}>
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader className='d-flex align-items-center justify-content-between'>
                                    <H6>Revenue Report</H6>
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <Row>
                                            <Col md='2'>
                                                <Input style={inputFontStyle} type="select" name="roles" id="roles" onChange={this.handleChange}>
                                                    <option>Select Category</option>
                                                    {roles.map((role, i) =>
                                                        <option key={i} value={role.id}>{role.role_name}</option>
                                                    )}
                                                </Input>
                                            </Col>
                                            <Col md='3' className='mb-2'>
                                                <Button onClick={this.onSearch}>Search</Button>&nbsp;&nbsp;
                                                <Button onClick={this.onClear} type='reset'>Clear</Button>
                                            </Col><Col></Col>
                                            <Col md='2'>
                                                <div className="job-filter">
                                                    <div className="faq-form">
                                                        <Input style={inputFontStyle} type="text" placeholder="Search" value={searchData} onChange={this.DefaultSearchChange}/>
                                                        <Search className="search-icon" />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                    <Col md='12' className='mt-3' style={{ "marginBottom": "-15px" }}>
                                        <Row>
                                            <Col xl='3'>
                                            <Card style={boxStyle}>
                                                    <CardBody>
                                                        <div>
                                                            <span>Fare Collection</span>
                                                            <H6>90,92,405</H6>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col xl='3'>
                                                <Card style={boxStyle}>
                                                    <CardBody>
                                                        <div>
                                                            <span>Fare Collection (Online)</span>
                                                            <H6>75,00,218</H6>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col xl='3'>
                                                <Card style={boxStyle}>
                                                    <CardBody>
                                                        <div>
                                                            <span>Fare Collection (Cash)</span>
                                                            <H6>1,45,000</H6>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            <Col xl='3'>
                                                <Card style={boxStyle}>
                                                    <CardBody>
                                                        <div>
                                                            <span>Total Fare Collection</span>
                                                            <H6>1,67,37,623</H6>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Table ref="tbl" striped hover responsive bordered id="data-table-zero">
                                        <thead>
                                            <tr>
                                                <th onClick={() => this.handleTableSort('name')}>Date {sortedColumn === 'name' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                                                <th onClick={() => this.handleTableSort('user_name')}>Toll Booth Gate ID {sortedColumn === 'user_name' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                                                <th onClick={() => this.handleTableSort('time_stamp')}>Toll Booth Lane Number {sortedColumn === 'time_stamp' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                                                <th onClick={() => this.handleTableSort('role_id')}>Vehicle Type {sortedColumn === 'role_id' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                                                <th onClick={() => this.handleTableSort('role_id')}>Vehicle ID {sortedColumn === 'role_id' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                                                <th onClick={() => this.handleTableSort('role_id')}>Vehicle Number Plate {sortedColumn === 'role_id' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                                                <th onClick={() => this.handleTableSort('role_id')}>Fare Amount {sortedColumn === 'role_id' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                                                <th onClick={() => this.handleTableSort('role_id')}>Transaction ID {sortedColumn === 'role_id' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                                                <th>Method of Payment</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>11-07-2023</td>
                                                <td>984W</td>
                                                <td>3</td>
                                                <td>Car</td>
                                                <td>345789675644545</td>
                                                <td>GJ 012342</td>
                                                <td>231</td>
                                                <td>12312312312312312</td>
                                                <td><button style={editBtnStyle} >Online</button></td>
                                            </tr>
                                            <tr>
                                                <td>11-07-2023</td>
                                                <td>984W</td>
                                                <td>3</td>
                                                <td>Car</td>
                                                <td>345789675644545</td>
                                                <td>GJ 012342</td>
                                                <td>231</td>
                                                <td>12312312312312312</td>
                                                <td><button style={editBtnStyle} >Online</button></td>
                                            </tr>
                                            <tr>
                                                <td>11-07-2023</td>
                                                <td>984W</td>
                                                <td>3</td>
                                                <td>Car</td>
                                                <td>345789675644545</td>
                                                <td>GJ 012342</td>
                                                <td>231</td>
                                                <td>12312312312312312</td>
                                                <td><button style={editBtnStyle} >Online</button></td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </CardBody>
                                <CardFooter>
                                    <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={this.handlePageChange} />
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}
const mapStateToProps = () => { return {} };

const mapDispatchToProps = {
    listUsers: userOperations.listUsers,
    listRoles: roleOperations.listRoles
};

export default connect(mapStateToProps, mapDispatchToProps)(RevenueReport)