export const INSERT_USER = "user/INSERT_USER";
export const INSERT_USER_COMPLETED = "user/INSERT_USER_COMPLETED";
export const INSERT_USER_FAILED = "user/INSERT_USER_FAILED";

export const FETCH_USER_LIST = "user/FETCH_USER_LIST";
export const FETCH_USER_LIST_COMPLETED = "user/FETCH_USER_LIST_COMPLETED";
export const FETCH_USER_LIST_FAILED = "user/FETCH_USER_LIST_FAILED";

export const UPDATE_USER = "user/UPDATE_USER";
export const UPDATE_USER_COMPLETED = "user/UPDATE_USER_COMPLETED";
export const UPDATE_USER_FAILED = "user/UPDATE_USER_FAILED";

export const DELETE_USER = "user/DELETE_USER";
export const DELETE_USER_COMPLETED = "user/DELETE_USER_COMPLETED";
export const DELETE_USER_FAILED = "user/DELETE_USER_FAILED";