import * as types from "./types";
import * as API_URL from "../../../env";

export const addUser = (payload) => ({
    type: types.INSERT_USER,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/user/add-user",
        method: "POST",
        body: payload
    },
});

export const listUsers = (payload) => ({
    type: types.FETCH_USER_LIST,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/user/list-users",
        method: "POST",
        body: payload
    },
});

export const updateUser = (user) => ({
    type: types.UPDATE_USER,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/user/update-user",
        method: "PUT",
        body: user
    },
});

export const deleteUser = (payload) => ({
    type: types.DELETE_USER,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/user/delete-user",
        method: "PUT",
        body: payload
    },
});