import React, { Fragment } from "react";
import ReactApexChart from 'react-apexcharts';
import { Container, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import SvgIcon from '../../../Components/Common/Component/SvgIcon';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Chart from 'react-apexcharts';
import { H3, H5 } from '../../../AbstractElements';
import { MonthlyProfitsChartData } from '../../../Data/Ecommerce/Chart';
import { OptionsOverView } from '../../../Data/Ecommerce/Chart';
import { OptionsVisitorChart } from '../../../Data/Ecommerce/Chart';
import { DailyDropdown } from '../../../Constant';
import DropdownCommon from '../../../Components/Common/Dropdown';
import { optionsCandleStickChart } from '../../../Components/Common/Data/ApexChart';

class Report extends React.Component {

    onRevenueReportClick = () => {
        window.open("/reports/revenue-report/", "_self");
    }

    onRealTimeReportClick = () => {
        window.open("/reports/real-time-report/", "_self");
    }

    onDisputeReportClick = () => {
        window.open("/reports/dispute-report/", "_self");
    }

    render() {
        return (
            <Fragment>
                <div className='page-title'>
                    <Row>
                        <Col xs='6'>
                            <H3>Report</H3>
                        </Col>
                        <Col xs='6'>
                            <ol className='breadcrumb'>
                                <li className='breadcrumb-item'>
                                    <Link to="/dashboard/">
                                        <SvgIcon iconId='stroke-home' />
                                    </Link>
                                </li>
                                <li className='breadcrumb-item active'>Report</li>
                            </ol>
                        </Col>
                    </Row>
                </div>
                <Container fluid={true}>
                    <Row className="widget-grid">
                        <Col md="6">
                            <Card className='card market-card' onClick={this.onRevenueReportClick}>
                                <CardHeader className='card-no-border'>
                                    <H5>Revenue Report</H5>
                                </CardHeader>
                                <CardBody className='pt-0'>
                                    <Row className='m-0 overall-card'>
                                        <Col xxl='8' xl='7' md='8' sm='7' className='p-0 box-col-7 col-ed-7'>
                                            <div className='market-chart-container'>
                                                <Chart height={270} width={450} options={optionsCandleStickChart.options} series={optionsCandleStickChart.series} type='candlestick' />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="6">
                            <Card onClick={this.onRealTimeReportClick}>
                                <CardHeader className='card-no-border'>
                                    <H5>Real Time (User Activity) Report</H5>
                                </CardHeader>
                                <CardBody className='pt-0'>
                                    <Row className='m-0 overall-card'>
                                        <Col xxl='8' xl='7' md='8' sm='7' className='p-0 box-col-7 col-ed-7'>
                                            <div className='market-chart-container'>
                                                <ReactApexChart className='overview-wrapper' type='line' height={270} width={450} options={OptionsOverView.options} series={OptionsOverView.series} />
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="6">
                            <Card onClick={this.onDisputeReportClick}>
                                <CardHeader className='card-no-border'>
                                    <H5>Dispute Report</H5>
                                </CardHeader>
                                <CardBody className='pt-0'>
                                    <div className='monthly-profit'>
                                        <ReactApexChart type='donut' height={270} width={450} series={MonthlyProfitsChartData.series} options={MonthlyProfitsChartData.options} />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="6">
                            <Card className='visitor-card'>
                                <CardHeader className='card-no-border'>
                                    <div className='header-top'>
                                        <H5>Failure Report</H5>
                                        <div className='card-header-right-icon'>
                                            <DropdownCommon dropdownMain={{ className: 'icon-dropdown', direction: 'start' }} options={DailyDropdown} iconName='icon-more-alt' btn={{ tag: 'span' }} />
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody className='pt-0'>
                                    <div className='visitors-container'>
                                        <ReactApexChart height={270} width={450} type='bar' options={OptionsVisitorChart.options} series={OptionsVisitorChart.series} />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
};
const mapStateToProps = () => { return {} };

const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Report);