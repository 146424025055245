import React, { Component, Fragment } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import "../../../assets/scss/progress.tracker.css";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import * as API_URL from "../../../env.js";
import request from "../../../utils/request.js";
import SvgIcon from '../../../Components/Common/Component/SvgIcon.jsx';
import { H5, Btn } from "../../../AbstractElements.jsx";
import { CardBody, CardHeader, Form, FormGroup, Label, Input, Row, Col, Card, Container, CardFooter } from 'reactstrap';
import { Cancel, Update } from "../../../Constant/index.jsx";
import { parkingPlazaOperations } from '../../../state/ducks/parking-plaza/index.js';
import { laneOperations } from '../../../state/ducks/lane';
import { gateOperations } from '../../../state/ducks/gate';

class EditLane extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedLane: {},
            plaza: [],
            gate: [],
            laneData: [],
            listLaneDetailsByGateId: [],
            lane_status: true,
            is_available_lane: 0
        };
    }

    async componentDidMount() {
        this.loadLocalStorageData();
        const id = localStorage.getItem("selectedLaneId");
        const options = {
            url: API_URL.BACKEND_SERVICE_API_URL + `/lane/get-lane-details/${id}`,
            method: "GET",
            headers: {
                authorization: localStorage.getItem('token')
            }
        };
        let response = await request(options);
        this.setState({ selectedLane: response.payload });

        // Get Gates Details By PlazaId
        let response2 = await this.props.listGateDetailsByPlazaId({ plazaId: response.payload.plaza_id });
        this.setState({ gate: response2.payload });

        // list lane details by lane id
        let laneResponse = await this.props.listLaneDetailsByGateId({ gateName: response.payload.gate_name });
        if (laneResponse.payload.length === 0) {
            this.setState({ is_available_lane: 0 });
        } else {
            this.setState({ listLaneDetailsByGateId: laneResponse.payload, is_available_lane: 1 });
        }
    }

    loadLocalStorageData = async () => {
        // Store plaza
        localStorage.removeItem('plaza');
        let plaza = localStorage.getItem('plaza');
        if (plaza) {
            plaza = JSON.parse(plaza);
            this.setState({ plaza });
        } else {
            let response = await this.props.listParkingPlaza();
            if (response.payload.length !== 0) {
                const searchString = JSON.stringify(response.payload);
                localStorage.setItem('plaza', searchString);
                plaza = JSON.parse(searchString);
                this.setState({ plaza });
            }
        }
    }

    handleInput = async (e) => {
        try {
            let tid = e.target.id;
            const { selectedLane } = this.state;
            switch (tid) {
                case 'plaza':
                    const plaza_id = e.target.value;
                    this.setState({
                        selectedLane: {
                            ...selectedLane,
                            plaza_id
                        }
                    });
                    let response = await this.props.listGateDetailsByPlazaId({ plazaId: e.target.value });
                    this.setState({ gate: response.payload });
                    response.payload.map((data) => this.setState({ gate_id: data.id }) );
                    break;
                case 'gate':
                    const gate_name = e.target.value;
                    this.setState({
                        selectedLane: {
                            ...selectedLane,
                            gate_name
                        }
                    });
                    // list lane details by lane id
                    let laneResponse = await this.props.listLaneDetailsByGateId({ gateName: e.target.value });
                    if (laneResponse.payload.length === 0) {
                        this.setState({ is_available_lane: 0 });
                    } else {
                        this.setState({ listLaneDetailsByGateId: laneResponse.payload, is_available_lane: 1 });
                    }

                    break;
                case 'description':
                    const description = e.target.value;
                    this.setState({
                        selectedLane: {
                            ...selectedLane,
                            description
                        }
                    });
                    break;
                default:
                    console.log("No Matching target found in handleInput");
                    break;
            }
        } catch (err) {
            console.log(err);
        }
    }

    // multiple textbox create for lane
    handleNoOfLaneChange = (e) => {
        e.preventDefault();
        const newLaneCount = parseInt(e.target.value, 10) || 0;
        this.setState((prevState) => {
            const existingLanes = prevState.selectedLane.lanes.slice(0, newLaneCount);
            const newLanes = Array.from({ length: newLaneCount - existingLanes.length }, (_, i) => ({
                lane_name: '', lane_id: '', lane_number: '', associated_gate_no: '', lane_status: '',
                rfid_module_ip_address: '', rfid_module_ip_number: '', rfid_module_ip_password: '', rfid_module_url: '', camera_address: '',
                camera_id: '', camera_password: '', camera_url: ''
            }));
            return {
                selectedLane: {
                    ...prevState.selectedLane,
                    no_of_lane: newLaneCount,
                    lanes: [...existingLanes, ...newLanes],
                },
            };
        });
    };

    handlePlusButtonClick = (e) => {
        e.preventDefault();
        this.setState((prevState) => {
            const newLaneCount = prevState.selectedLane.no_of_lane + 1;
            const newLanes = [...prevState.selectedLane.lanes, {
                lane_name: '', lane_id: '', lane_number: '', associated_gate_no: '', lane_status: '',
                rfid_module_ip_address: '', rfid_module_ip_number: '', rfid_module_ip_password: '', rfid_module_url: '', camera_address: '',
                camera_id: '', camera_password: '', camera_url: ''
            }];

            return {
                selectedLane: {
                    ...prevState.selectedLane,
                    no_of_lane: newLaneCount,
                    lanes: newLanes,
                },
            };
        });
    };

    handleMinusButtonClick = (e) => {
        e.preventDefault();
        this.setState((prevState) => {
            const newLaneCount = prevState.selectedLane.no_of_lane - 1;
            const newLanes = [...prevState.selectedLane.lanes, {
                lane_name: '', lane_id: '', lane_number: '', associated_gate_no: '', lane_status: '',
                rfid_module_ip_address: '', rfid_module_ip_number: '', rfid_module_ip_password: '', rfid_module_url: '', camera_address: '',
                camera_id: '', camera_password: '', camera_url: ''
            }];

            return {
                selectedLane: {
                    ...prevState.selectedLane,
                    no_of_lane: newLaneCount,
                    lanes: newLanes,
                },
            };
        });
    }

    handleInputChange = (e, index, field) => {
        const targetId = e.target.id;
        this.setState((prevState) => {
            const newLanes = prevState.selectedLane.lanes.map((lane, i) => {
                if (i === index) {
                    switch (targetId) {
                        case "lane_name":
                            return { ...lane, lane_name: e.target.value };
                        case "lane_id":
                            return { ...lane, lane_id: e.target.value };
                        case "lane_number":
                            return { ...lane, lane_number: e.target.value };
                        case "lane_status":
                            return { ...lane, lane_status: !lane.lane_status };
                        case "rfid_module_ip_address":
                            return { ...lane, rfid_module_ip_address: e.target.value };
                        case "rfid_module_ip_number":
                            return { ...lane, rfid_module_ip_number: e.target.value };
                        case "rfid_module_ip_password":
                            return { ...lane, rfid_module_ip_password: e.target.value };
                        case "rfid_module_url":
                            return { ...lane, rfid_module_url: e.target.value };
                        case "camera_address":
                            return { ...lane, camera_address: e.target.value };
                        case "camera_id":
                            return { ...lane, camera_id: e.target.value };
                        case "camera_password":
                            return { ...lane, camera_password: e.target.value };
                        case "camera_url":
                            return { ...lane, camera_url: e.target.value };
                        default:
                            return lane;
                    }
                }
                return lane;
            });

            return {
                selectedLane: {
                    ...prevState.selectedLane,
                    lanes: newLanes,
                },
            };
        });
    };

    laneInformationInputFiled = () => {
        const { selectedLane } = this.state;
        const inputFontStyle = { fontSize: '10px' };
        const plusStyle = { border: "#48bb78 2px solid", backgroundColor: "#48bb78", color: "#fff", fontWeight: "bold" };
        const minusStyle = { border: "#ff6666 2px solid", backgroundColor: "#ff6666", color: "#fff", fontWeight: "bold" };
        const laneTextbox = [];

        for (let i = 0; i < selectedLane.no_of_lane; i++) {
            if (selectedLane.lanes && selectedLane.lanes[i]) {
                laneTextbox.push(
                    <Row key={i} style={{ "marginBottom": "5px", "background": "#d9d9d9", "borderRadius": "10px" }}>
                        <p style={{ "fontSize": "12px", "color": "#000", "fontWeight": "bold" }}>Lane {i + 1} Information</p>
                        <Col md="3">
                            <FormGroup>
                                <Label>Lane Name</Label>
                                <Input
                                    type="text"
                                    style={inputFontStyle}
                                    id="lane_name"
                                    placeholder="Enter Lane Name"
                                    value={selectedLane.lanes[i].lane_name || ''}
                                    onChange={(e) => this.handleInputChange(e, i, 'lane_name')}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="3">
                            <FormGroup>
                                <Label>Lane ID</Label>
                                <Input
                                    type="text"
                                    style={inputFontStyle}
                                    id="lane_id"
                                    placeholder="Enter Lane ID"
                                    value={selectedLane.lanes[i].lane_id || ''}
                                    onChange={(e) => this.handleInputChange(e, i, 'lane_id')}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="3">
                            <FormGroup>
                                <Label>Lane Number</Label>
                                <Input
                                    type="text"
                                    style={inputFontStyle}
                                    id="lane_number"
                                    placeholder="Enter Lane Number"
                                    value={selectedLane.lanes[i].lane_number || ''}
                                    onChange={(e) => this.handleInputChange(e, i, 'lane_number')}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="2">
                            <FormGroup>
                                <Label>Lane Status</Label>
                                <label className="switch">
                                    <Input
                                        type="checkbox"
                                        id="lane_status"
                                        checked={selectedLane.lanes[i].lane_status || false}
                                        onChange={(e) => this.handleInputChange(e, i, 'lane_status')}
                                    />
                                    <span className="slider"></span>
                                </label>
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <Label>RFID Module IP Address</Label>
                                <Input
                                    type="text"
                                    style={inputFontStyle}
                                    id="rfid_module_ip_address"
                                    placeholder="Enter IP Address"
                                    value={selectedLane.lanes[i].rfid_module_ip_address || ''}
                                    onChange={(e) => this.handleInputChange(e, i, 'rfid_module_ip_address')}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <Label>RFID Module IP Number</Label>
                                <Input
                                    type="text"
                                    style={inputFontStyle}
                                    id="rfid_module_ip_number"
                                    placeholder="Enter IP Number"
                                    value={selectedLane.lanes[i].rfid_module_ip_number || ''}
                                    onChange={(e) => this.handleInputChange(e, i, 'rfid_module_ip_number')}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <Label>RFID Module IP Password</Label>
                                <Input
                                    type="password"
                                    id="rfid_module_ip_password"
                                    style={inputFontStyle}
                                    placeholder="Enter IP Password"
                                    value={selectedLane.lanes[i].rfid_module_ip_password || ''}
                                    onChange={(e) => this.handleInputChange(e, i, 'rfid_module_ip_password')}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="12">
                            <FormGroup>
                                <Label>RFID Module URL</Label>
                                <Input
                                    type="text"
                                    id="rfid_module_url"
                                    style={inputFontStyle}
                                    placeholder="Enter URL"
                                    value={selectedLane.lanes[i].rfid_module_url || ''}
                                    onChange={(e) => this.handleInputChange(e, i, 'rfid_module_url')}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <Label>Camera Module IP Address</Label>
                                <Input
                                    type="text"
                                    id="camera_address"
                                    style={inputFontStyle}
                                    placeholder="Enter IP Address"
                                    value={selectedLane.lanes[i].camera_address || ''}
                                    onChange={(e) => this.handleInputChange(e, i, 'camera_address')}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <Label>Camera Module IP Number</Label>
                                <Input
                                    type="text"
                                    style={inputFontStyle}
                                    id="camera_id"
                                    placeholder="Enter IP Number"
                                    value={selectedLane.lanes[i].camera_id || ''}
                                    onChange={(e) => this.handleInputChange(e, i, 'camera_id')}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <Label>Camera Module Password</Label>
                                <Input
                                    type="password"
                                    id="camera_password"
                                    style={inputFontStyle}
                                    placeholder="Enter Password"
                                    value={selectedLane.lanes[i].camera_password || ''}
                                    onChange={(e) => this.handleInputChange(e, i, 'camera_password')}
                                />
                            </FormGroup>
                        </Col><Col md="2"></Col><Col md="1"></Col>
                        <Col md="10">
                            <FormGroup>
                                <Label>Camera Module URL</Label>
                                <Input
                                    type="text"
                                    id="camera_url"
                                    style={inputFontStyle}
                                    placeholder="Enter URL"
                                    value={selectedLane.lanes[i].camera_url || ''}
                                    onChange={(e) => this.handleInputChange(e, i, 'camera_url')}
                                />
                            </FormGroup>
                        </Col>
                        <Col style={{ "marginTop": "24px" }}>
                            <button type="button" style={plusStyle} onClick={this.handlePlusButtonClick}>+</button>&nbsp;&nbsp;
                            <button type="button" style={minusStyle} onClick={this.handleMinusButtonClick}>-</button>
                        </Col>
                    </Row>
                );
            }
        }
        return laneTextbox;
    };

    onUpdate = async (e) => {
        e.preventDefault()
        try {
            const { selectedLane } = this.state;
            let response = await this.props.updateLane({
                id: selectedLane.id,
                plaza_id: selectedLane.plaza_id,
                gate_id: this.state.gate_id,
                gate_name: this.state.gate_name,
                no_of_lane: selectedLane.no_of_lane,
                lanes: selectedLane.lanes,
                description: selectedLane.description
            });
            toast.success(response.message, { autoClose: 10000 });
            window.open("/lane/", "_self");
        } catch (err) {
            toast.error(err.response.data.message, { autoClose: 10000 });
            console.log(err);
        }
    }

    render() {
        const { selectedLane, plaza, gate, listLaneDetailsByGateId, is_available_lane } = this.state;
        const inputFontStyle = { fontSize: '10px' };
        const activeStyle = { "width": "80px", "backgroundColor": "#ccffcc", "color": "#008000", "borderRadius": "15px", "fontSize": "10px", "textAlign": "center" }
        const inactiveStyle = { "width": "80px", "backgroundColor": "#ffe6e6", "color": "#ff0000", "borderRadius": "15px", "fontSize": "10px", "textAlign": "center" }
        return (
            <Fragment>
                <div className='page-title'>
                    <Row>
                        <Col xs='6'></Col>
                        <Col xs='6'>
                            <ol className='breadcrumb'>
                                <li className='breadcrumb-item'>
                                    <Link to="/dashboard/">
                                        <SvgIcon iconId='stroke-home' />
                                    </Link>
                                </li>
                                <li className='breadcrumb-item'>
                                    <Link style={{ "color": "#000" }} to="/lane/">Lane</Link>
                                </li>
                                <li className='breadcrumb-item active'>Edit Lane</li>
                            </ol>
                        </Col>
                    </Row>
                </div>
                <ToastContainer />
                <Container fluid={true} className='search-page'>
                    <Row>
                        <Col sm='12'>
                            <Form onSubmit={this.onUpdate}>
                                <Card>
                                    <CardHeader className='d-flex align-items-center justify-content-between'>
                                        <H5>Edit Lane</H5>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col md="1"></Col>
                                            <Col md="5">
                                                <FormGroup>
                                                    <Label>Select Parking Plaza</Label>
                                                    <Input style={inputFontStyle} type="select" id="plaza" value={selectedLane.plaza_id || ''} onChange={this.handleInput}>
                                                        <option>Select Parking Plaza</option>
                                                        {plaza.map((data, i) =>
                                                            <option key={i} value={data.id}>{data.p_name}</option>
                                                        )}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col md="5">
                                                <FormGroup>
                                                    <Label>Select Gate</Label>
                                                    <Input style={inputFontStyle} type="select" id="gate" value={selectedLane.gate_name || ''} onChange={this.handleInput}>
                                                        <option>Select Gate</option>
                                                        {gate.map((data, i) =>
                                                            <option key={i} value={data.gate_name}>{data.gate_name}</option>
                                                        )}
                                                    </Input>
                                                </FormGroup>
                                            </Col><Col md="6"></Col>
                                            <Col md="1"></Col>
                                            {is_available_lane !== 0 ? <span style={{ "marginLeft": "105px" }}>
                                                <Label>Available Lane</Label>
                                                <Col md="8">
                                                    <table id="customers">
                                                        <thead>
                                                            <tr>
                                                                <th>Lane Name</th>
                                                                <th>Lane ID</th>
                                                                <th>Lane Number</th>
                                                                <th>Lane Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {listLaneDetailsByGateId.map((data, i) =>
                                                                <tr key={i}>
                                                                    <td>{data.lane_name}</td>
                                                                    <td>{data.lane_id}</td>
                                                                    <td>{data.lane_number}</td>
                                                                    {data.lane_status ? <td><p style={activeStyle}>Active</p></td> : <td><p style={inactiveStyle}>Inactive</p></td>}
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </Col>
                                            </span> : <span></span>}
                                            <Col md="1"></Col>
                                            <Col md="10">
                                                <FormGroup>
                                                    <Label>No of Lane</Label>
                                                    <Input
                                                        type="number"
                                                        style={inputFontStyle}
                                                        placeholder="Enter No of Lane Associated"
                                                        id="no_of_lane"
                                                        value={selectedLane.no_of_lane || ''}
                                                        onChange={this.handleNoOfLaneChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {selectedLane.no_of_lane === 0 ? <span></span> :
                                            <Row style={{ "marginLeft": "100px", "marginRight": "80px" }}>
                                                {this.laneInformationInputFiled()}
                                            </Row>
                                        }
                                        <Row>
                                            <Col md="1"></Col>
                                            <Col md="10">
                                                <FormGroup className="mb-3">
                                                    <Label className="form-label">Description</Label>
                                                    <Input style={inputFontStyle} type="textarea" placeholder="Enter Description" id="description" value={selectedLane.description || ''} onChange={this.handleInput} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter className="text-center">
                                        <Btn attrBtn={{ color: "primary", type: "submit" }} >{Update}</Btn>&nbsp;&nbsp;&nbsp;
                                        <Link to="/lane/">
                                            <Btn attrBtn={{ color: "primary", type: "reset" }} >{Cancel}</Btn>
                                        </Link>
                                    </CardFooter>
                                </Card>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}
const mapStateToProps = () => { return {} };

const mapDispatchToProps = {
    updateLane: laneOperations.updateLane,
    listParkingPlaza: parkingPlazaOperations.listParkingPlaza,
    listLaneDetailsByGateId: laneOperations.listLaneDetailsByGateId,
    listGateDetailsByPlazaId: gateOperations.listGateDetailsByPlazaId
};

export default connect(mapStateToProps, mapDispatchToProps)(EditLane)