import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Form, FormGroup, Input, Label, Row, Col } from 'reactstrap';
import { Btn, H4, P, Image } from '../../../AbstractElements';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import logoWhite from '../../../assets/images/logo/logo.png';
import { Email, FirstName, LastName, Username, Password } from '../../../Constant';
import { authOperations } from '../../../state/ducks/auth';
import { validEmail, validPassword } from '../../validation.jsx';

class RegisterFrom extends React.Component {

  constructor() {
    super();
    this.state = {
      pass_word: '',
      togglePassword: false,
      isEmailValid: true,
      isPasswordValid: true,
    }
  }

  togglePasswordVisibility = () => {
    this.setState((prevState) => ({ togglePassword: !prevState.togglePassword }));
  };

  onInsert = async (event) => {
    event.preventDefault();
    try {
      let insertResponse = await this.props.signup({
        f_name: this.state.firstName,
        l_name: this.state.lastName,
        user_name: this.state.userName,
        email: this.state.email,
        pass_word: this.state.pass_word
      });
      toast.success(insertResponse.message, { autoClose: 10000 });
      this.setState({
        firstName: '',
        lastName: '',
        userName: '',
        email: '',
        pass_word: ''
      });
      window.open("/login/","_self");
    } catch (err) {
      toast.error(err.response.data.message, { autoClose: 10000 });
      console.log(err);
    }
  }

  handleChange = async (e) => {
    try {
      let tid = e.target.id;
      switch(tid){
        case 'email':
          const email = e.target.value;
          const isEmailValid = !email || validEmail.test(email);
          this.setState({ email: email, isEmailValid });
          break;
        case 'pass_word':
          const pass_word = e.target.value;
          const isPasswordValid = !pass_word || validPassword.test(pass_word);
          this.setState({ pass_word: pass_word, isPasswordValid });
          break;
        default:
          console.log("No Matching target found");
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const { togglePassword } = this.state;
    return (
      <Fragment>
        <ToastContainer />
        <div className='login-card'>
          <div>
            <div className='login-main'>
              <div>
                <Link className="logo" to="/signup/">
                  <Image attrImage={{ className: 'img-fluid for-light', src: logoWhite, alt: 'looginpage' }} />
                </Link>
              </div>
              <Form className='theme-form login-form' onSubmit={this.onInsert}>
                <H4>Sign Up</H4>
                <P>Enter your personal details to create account</P>
                <Row>
                  <Col xs='6'>
                    <Label className='col-form-label m-0 pt-0'>{FirstName}</Label>
                    <Input className='form-control' type='text' required placeholder='Fist Name' onChange={(e) => this.setState({ firstName: e.target.value })} />
                  </Col>
                  <Col xs='6'>
                    <Label className='col-form-label m-0 pt-0'>{LastName}</Label>
                    <Input className='form-control' type='text' required placeholder='Last Name' onChange={(e) => this.setState({ lastName: e.target.value })} />
                  </Col>
                </Row>
                <Col>
                  <Label className='col-form-label m-0 pt-0'>{Username}</Label>
                  <Input className='form-control' type='text' required placeholder='User Name' onChange={(e) => this.setState({ userName: e.target.value })} />
                </Col>
                <Col>
                  <Label className='col-form-label m-0 pt-0'>{Email}</Label>
                  <Input type="email" name="email" id="email" required placeholder="Email" value={this.state.email || ''} onChange={this.handleChange} />
                  {this.state.isEmailValid ? <span></span> : <span style={{ color: "#F61C04" }}>Email Id is not valid.</span>}
                </Col>
                <FormGroup className='position-relative'>
                  <Label className='col-form-label m-0 pt-0'>{Password}</Label>
                  <div className='position-relative'>
                    <Input className='form-control' type={togglePassword ? 'text' : 'password'} placeholder='Password' name="pass_word" id="pass_word" required value={this.state.pass_word || ''} onChange={this.handleChange}  />
                    {this.state.isPasswordValid ? <span></span> : <span style={{ color: "#F61C04" }}>Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, and one digit.</span>}
                    <div className='show-hide' onClick={this.togglePasswordVisibility}>
                      <span className={togglePassword ? '' : 'show'}></span>
                    </div>
                  </div>
                </FormGroup>
                <FormGroup>
                  <Btn attrBtn={{ className: 'd-block w-100', color: 'primary', type: 'submit' }}>Sign Up</Btn>
                </FormGroup>
                <P attrPara={{ className: 'mb-0 text-start' }}>Already have an account?
                  <Link className='ms-2' to="/login/">Sign in</Link>
                </P>
              </Form>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };
}
const mapStateToProps = () => { return {} };

const mapDispatchToProps = { signup: authOperations.signup };

export default connect(mapStateToProps, mapDispatchToProps)(RegisterFrom)