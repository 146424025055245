import React, { Component, Fragment } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import "../../../assets/scss/progress.tracker.css";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import * as API_URL from "../../../env.js";
import request from "../../../utils/request";
import SvgIcon from '../../../Components/Common/Component/SvgIcon.jsx';
import { H5, Btn } from "../../../AbstractElements.jsx";
import { CardBody, CardHeader, Form, FormGroup, Label, Input, Row, Col, Card, Container, CardFooter } from 'reactstrap';
import { Cancel, Update } from "../../../Constant/index.jsx";
import { parkingPlazaOperations } from '../../../state/ducks/parking-plaza/index.js';
import { masterOperations } from '../../../state/ducks/master/index.js';
import { filterCitiesByState } from "../../../utils/helper.js";
import { validEmail, validMobileNumber } from '../../validation.jsx';

class EditParkingPlaza extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedPlazaData: {},
            isEmailValid: true,
            isMobileValid: true,
            states: [],
            cities: []
        };
    }

    async componentDidMount() {
        this.loadLocalStorageData();
        const plazaId = localStorage.getItem("selectedPlazaId");
        const options = {
            url: API_URL.BACKEND_SERVICE_API_URL+`/parking-plaza/get-plaza-details/${plazaId}`,
            method: "GET",
            headers: {
                authorization: localStorage.getItem('token')
            }
        };
        let response = await request(options);
        this.setState({ selectedPlazaData: response.payload });
    }

    loadLocalStorageData = async () => {
        // Store Cities
        let cities = localStorage.getItem('cities');
        if (cities) {
            cities = JSON.parse(cities);
            this.setState({ cities });
        } else {
            let response = await this.props.listCity();
            if(response.payload.length !== 0) {
                const searchString = JSON.stringify(response.payload);            
                localStorage.setItem('cities', searchString);
                cities = JSON.parse(searchString);
                this.setState({ cities });
            }
        }
        // Store States
        let states = localStorage.getItem('states');
        if (states) {
            states = JSON.parse(states);
            this.setState({ states });
        } else {
            let response = await this.props.listState();
            if(response.payload.length !== 0) {
                const searchString = JSON.stringify(response.payload);            
                localStorage.setItem('states', searchString);
                states = JSON.parse(searchString);
                this.setState({ states });
            }
        }
    }

    handleInput = async (e) => {
        try {
            let tid = e.target.id;
            const { selectedPlazaData } = this.state;
            switch(tid){
                case 'p_name':
                    const p_name = e.target.value;           
                    this.setState({
                        selectedPlazaData: {
                            ...selectedPlazaData,
                            p_name
                        }
                    });
                    break;
                case 'p_id':
                    const p_id = e.target.value;           
                    this.setState({
                        selectedPlazaData: {
                            ...selectedPlazaData,
                            p_id
                        }
                    });
                    break;
                case 'npci_id':
                    const npci_id = e.target.value;           
                    this.setState({
                        selectedPlazaData: {
                            ...selectedPlazaData,
                            npci_id
                        }
                    });
                    break;
                case 'p_address':
                    const p_address = e.target.value;           
                    this.setState({
                        selectedPlazaData: {
                            ...selectedPlazaData,
                            p_address
                        }
                    });
                    break;
                case 'latitude':
                    const latitude = e.target.value;           
                    this.setState({
                        selectedPlazaData: {
                            ...selectedPlazaData,
                            latitude
                        }
                    });
                    break;
                case 'longitude':
                    const longitude = e.target.value;           
                    this.setState({
                        selectedPlazaData: {
                            ...selectedPlazaData,
                            longitude
                        }
                    });
                    break;
                case 'state':
                    const state_id = e.target.value;
                    this.setState({
                        selectedPlazaData: {
                            ...selectedPlazaData,
                            state_id
                        }
                    });
                    const cities =  filterCitiesByState(state_id);
                    this.setState({ cities });
                    break;
                case 'city':
                    const city_id = e.target.value;           
                    this.setState({
                        selectedPlazaData: {
                            ...selectedPlazaData,
                            city_id
                        }
                    });
                    break;
                case 'p_cont_per_name':
                    const p_cont_per_name = e.target.value;           
                    this.setState({
                        selectedPlazaData: {
                            ...selectedPlazaData,
                            p_cont_per_name
                        }
                    });
                    break;
                case 'p_cont_per_phone_no':
                    const p_cont_per_phone_no = e.target.value;           
                    this.setState({
                        selectedPlazaData: {
                            ...selectedPlazaData,
                            p_cont_per_phone_no
                        }
                    });
                    const isMobileValid = !p_cont_per_phone_no || validMobileNumber.test(p_cont_per_phone_no);
                    this.setState({ p_cont_per_phone_no: p_cont_per_phone_no, isMobileValid });
                    break;
                case 'p_cont_per_email':
                    const p_cont_per_email = e.target.value;           
                    this.setState({
                        selectedPlazaData: {
                            ...selectedPlazaData,
                            p_cont_per_email
                        }
                    });
                    const isEmailValid = !p_cont_per_email || validEmail.test(p_cont_per_email);
                    this.setState({ p_cont_per_email: p_cont_per_email, isEmailValid });
                    break;
                case 'p_desc':
                    const p_desc = e.target.value;           
                    this.setState({
                        selectedPlazaData: {
                            ...selectedPlazaData,
                            p_desc
                        }
                    });
                    break;
                default:
                    console.log("No Matching target found in handleInput");
                    break;
            }
        } catch (err) {
            console.log(err);
        }
    }

    onUpdate = async (e) => {
        e.preventDefault()
        try {
            const { selectedPlazaData } = this.state
            let response = await this.props.updateParkingPlaza({
                id: selectedPlazaData.id,
                p_name: selectedPlazaData.p_name,
                p_id: selectedPlazaData.p_id,
                npci_id: selectedPlazaData.npci_id,
                p_address: selectedPlazaData.p_address,
                latitude: selectedPlazaData.latitude,
                longitude: selectedPlazaData.longitude,
                state_id: selectedPlazaData.state_id,
                city_id: selectedPlazaData.city_id,
                p_cont_per_name: selectedPlazaData.p_cont_per_name,
                p_cont_per_phone_no: selectedPlazaData.p_cont_per_phone_no,
                p_cont_per_email: selectedPlazaData.p_cont_per_email,
                p_desc: selectedPlazaData.p_desc,
            });
            toast.success(response.message, { autoClose: 10000 });
            window.open("/parking-plazas/","_self");
        } catch (err) {
            toast.error(err.response.data.message, { autoClose: 10000 });
            console.log(err);
        }
    }

    render() {
        const { selectedPlazaData, states, cities } = this.state;
        const inputFontStyle = { fontSize: '10px' };
        return (
            <Fragment>
                <div className='page-title'>
                    <Row>
                        <Col xs='6'></Col>
                        <Col xs='6'>
                            <ol className='breadcrumb'>
                                <li className='breadcrumb-item'>
                                    <Link to="/dashboard/">
                                        <SvgIcon iconId='stroke-home' />
                                    </Link>
                                </li>
                                <li className='breadcrumb-item'>
                                    <Link style={{"color":"#000"}} to="/parking-plazas/">Parking Plaza</Link>
                                </li>
                                <li className='breadcrumb-item active'>Edit Parking Plaza</li>
                            </ol>
                        </Col>
                    </Row>
                </div>
                <ToastContainer />
                <Container fluid={true} className='search-page'>
                    <Row>
                        <Col sm='12'>
                            <Form onSubmit={this.onUpdate}>
                                <Card>
                                    <CardHeader className='d-flex align-items-center justify-content-between'>
                                        <H5>Edit Parking Plaza</H5>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col md="1"></Col>
                                            <Col md="8">
                                                <FormGroup>
                                                    <Label>Parking Plaza Name</Label>
                                                    <Input style={inputFontStyle} type="text" placeholder="Enter Parking Plaza Name" id="p_name" value={selectedPlazaData.p_name || ''} onChange={this.handleInput} />
                                                </FormGroup>
                                            </Col><Col md="3"></Col><Col md="1"></Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label>Parking Plaza ID</Label>
                                                    <Input style={inputFontStyle} type="text" placeholder="Enter Parking Plaza ID" id="p_id" value={selectedPlazaData.p_id || ''} onChange={this.handleInput} />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label>Parking Toll ID</Label>
                                                    <Input style={inputFontStyle} type="text" placeholder="Enter NPCI ID" id="npci_id" value={selectedPlazaData.npci_id || ''} onChange={this.handleInput} />
                                                </FormGroup>
                                            </Col><Col md="3"></Col><Col md="1"></Col>
                                            <Col md="8">
                                                <FormGroup>
                                                    <Label>Address</Label>
                                                    <Input style={inputFontStyle} type="textarea" placeholder="Enter Parking Plaza Address" id="p_address" value={selectedPlazaData.p_address || ''} onChange={this.handleInput} />
                                                </FormGroup>
                                            </Col><Col md="3"></Col><Col md="1"></Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label>Latitude</Label>
                                                    <Input type="text" style={inputFontStyle} id="latitude" placeholder="Enter Latitude" 
                                                        value={selectedPlazaData.latitude || ''} onChange={this.handleInput} />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label>Longitude</Label>
                                                    <Input type="text" style={inputFontStyle} id="longitude" placeholder="Enter Longitude" 
                                                        value={selectedPlazaData.longitude || ''} onChange={this.handleInput} />
                                                </FormGroup>
                                            </Col><Col md="3"></Col><Col md="1"></Col>
                                            <Col md="4">
                                                <FormGroup> 
                                                    <Label>State</Label>
                                                    <Input style={inputFontStyle} type="select" id="state" value={selectedPlazaData.state_id || ''} onChange={this.handleInput} >
                                                        <option>Select State</option>
                                                            {states.map((state, i) =>
                                                                <option key={i} value={state.id}>{state.name}</option>
                                                            )}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup> 
                                                    <Label>City</Label>
                                                    <Input style={inputFontStyle} type="select" id="city" value={selectedPlazaData.city_id || ''} onChange={this.handleInput} >
                                                        <option>Select City</option>
                                                        {cities.map((city, i) =>
                                                            <option key={i} value={city.id}>{city.name}</option>
                                                        )}
                                                    </Input>
                                                </FormGroup>
                                            </Col><Col md="3"></Col><Col md="1"></Col>
                                            <Col md="8">
                                                <FormGroup className="mb-3"> 
                                                    <Label className="form-label">Parking Plaza Contact Person Name</Label>
                                                    <Input style={inputFontStyle} type="text" placeholder="Enter Contact Person Name" id="p_cont_per_name" value={selectedPlazaData.p_cont_per_name || ''} onChange={this.handleInput} />
                                                </FormGroup>
                                            </Col><Col md="3"></Col><Col md="1"></Col>
                                            <Col md="4">
                                                <FormGroup> 
                                                    <Label>Phone Number</Label>
                                                    <Input style={inputFontStyle} type="text" placeholder="Enter Phone Number" id="p_cont_per_phone_no" value={selectedPlazaData.p_cont_per_phone_no || ''} onChange={this.handleInput} />
                                                    {this.state.isMobileValid ? <span></span> : <span style={{ color: "#F61C04" }}>Phone Number must be at least 10 digits.</span>}
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup> 
                                                    <Label>Email</Label>
                                                    <Input style={inputFontStyle} type="email" placeholder="Enter Email" id="p_cont_per_email" value={selectedPlazaData.p_cont_per_email || ''} onChange={this.handleInput} />
                                                </FormGroup>
                                                {this.state.isEmailValid ? <span></span> : <span style={{ color: "#F61C04" }}>Email Id is not valid.</span>}
                                            </Col><Col md="3"></Col><Col md="1"></Col> 
                                            <Col md="8">
                                                <FormGroup>
                                                    <Label>Description</Label>
                                                    <Input style={inputFontStyle} type="textarea" placeholder="Enter Parking Plaza Description" id="p_desc" value={selectedPlazaData.p_desc || ''} onChange={this.handleInput} />
                                                </FormGroup>
                                            </Col>              
                                        </Row>
                                    </CardBody>
                                    <CardFooter className="text-center">
                                        <Btn attrBtn={{ color: "primary", type: "submit" }} >{Update}</Btn>&nbsp;&nbsp;&nbsp;
                                        <Link to="/parking-plazas/">
                                            <Btn attrBtn={{ color: "primary", type: "reset" }} >{Cancel}</Btn>
                                        </Link>
                                    </CardFooter>
                                </Card>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}
const mapStateToProps = () => { return {} };

const mapDispatchToProps = {
    updateParkingPlaza: parkingPlazaOperations.updateParkingPlaza,
    listState: masterOperations.listState,
    listCity: masterOperations.listCity
};

export default connect(mapStateToProps, mapDispatchToProps)(EditParkingPlaza)