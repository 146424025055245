export const MENUITEMS = [
  {
    Items: [
      { path: "/dashboard", title: "Dashboard", type: "link", icon: "home" },
      { path: "/access-management", title: "Access-Management", type: "link", icon: "widget" },
      { path: "/parking-plazas", title: "Parking-Plaza", type: "link", icon: "project" },
      { path: "/gate", title: "Gate", type: "link", icon: "project" },
      { path: "/lane", title: "Lane", type: "link", icon: "project" },
      { path: "/logs", title: "Logs", type: "link", icon: "project" },
      { path: "/reports", title: "Report", type: "link", icon: "others" },
      { path: "/dispute-managements", title: "Dispute-Management", type: "link", icon: "user" },
      {/* path: "/dashboard", title: "Settings & Administration", type: "link", icon: "social" */}
    ]
  }
];