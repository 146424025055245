import React from 'react';
import { H6 } from '../../../AbstractElements';
import Slider from 'react-slick';

class NotificationSlider extends React.Component {

  render() {
    return (
      <div className='notification-slider overflow-hidden '>
        <Slider>
          <H6>
            <span style={{"color": "#000099", "fontSize": "20px"}}>Welcome to Toll Management System</span>
          </H6>
        </Slider>
      </div>
    );
  };
}

export default NotificationSlider;