import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Row, Col, Card, CardBody, CardHeader, FormGroup } from 'reactstrap';
import SvgIcon from '../../Components/Common/Component/SvgIcon';
import { H3, H6 } from '../../AbstractElements';
import { reportOperations } from '../../state/ducks/report';
import '../../assets/scss/toggle.css';

class DebugMode extends React.Component {
    constructor() {
        super();
        this.state = {
            debugModeData: '',
            isChecked: false,
        };
    }

    handleCheckboxChange = () => {
        this.setState((prevState) => ({
            isChecked: !prevState.isChecked,
        }));
    };

    async componentDidMount() {
        try {
            const response = await this.props.getDebugModeError();
            console.log(response);
            this.setState({ debugModeData: response.payload });
        } catch (err) {
            console.log(err);
        }
    }

    render() {
        const { debugModeData, isChecked } = this.state;
        console.log(isChecked);
        const style = {
            fontSize: "12px",
            border: "2px solid #e6e6e6",
            borderRadius: "5px",
            padding: "10px",
            color: "#000",
            marginTop: "15px",
            overflow: "scroll"
        };
        const labelStyle = {
            marginRight: '-0.5rem',
            fontWeight: "bold",
            color: "#000"
        };
        return (
            <Fragment>
                <div className='page-title'>
                    <Row>
                        <Col xs='6'>
                            <H3></H3>
                        </Col>
                        <Col xs='6'>
                            <ol className='breadcrumb'>
                                <li className='breadcrumb-item'>
                                    <Link to="/dashboard/">
                                        <SvgIcon iconId='stroke-home' />
                                    </Link>
                                </li>
                                <li className='breadcrumb-item active'>Debug Mode</li>
                            </ol>
                        </Col>
                    </Row>
                </div>
                <Container fluid={true}>
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader className='d-flex align-items-center justify-content-between'>
                                    <H6>Debug Mode</H6>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col md='1'></Col>
                                        <Col md='3'>
                                            <div className="d-flex align-items-center">
                                                <label style={labelStyle}>
                                                    {this.state.isChecked ? 'Debug Mode is ON' : 'Debug Mode is OFF'}
                                                </label>&nbsp;&nbsp;&nbsp;&nbsp;
                                                <label className="switch mb-1">
                                                    <input
                                                        type="checkbox"
                                                        checked={this.state.isChecked}
                                                        onChange={this.handleCheckboxChange}
                                                    />
                                                    <span className="slider"></span>
                                                </label>
                                            </div>
                                        </Col>
                                    </Row>
                                    {this.state.isChecked ?
                                        <Row>
                                            <Col md='1'></Col>
                                            <Col md='10'>
                                                <FormGroup>
                                                    <div style={style}>{debugModeData}</div>
                                                </FormGroup>
                                            </Col>
                                            <Col md='1'></Col>
                                        </Row> : <Row></Row>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}
const mapStateToProps = () => { return {} };

const mapDispatchToProps = {
    getDebugModeError: reportOperations.getDebugModeError
};

export default connect(mapStateToProps, mapDispatchToProps)(DebugMode)