import React, { Fragment } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { H3, Btn } from "../../../AbstractElements.jsx";
import SvgIcon from '../../../Components/Common/Component/SvgIcon';
import { Row, Col, CardBody, CardFooter, Form, FormGroup, Label, Input, Container, Card } from 'reactstrap';
import { Cancel, Email, Role, Update, Username, Password, FullName } from '../../../Constant/index.jsx';
import * as API_URL from "../../../env.js";
import request from "../../../utils/request";
import { userOperations } from '../../../state/ducks/user';
import { roleOperations } from '../../../state/ducks/role';
import { validEmail, validPassword } from '../../validation.jsx';

class UpdateUser extends React.Component {
    
    constructor() {
        super();
        this.state = {
            selectedUser: {},
            roles: [],
            isEmailValid: true,
            isPasswordValid: true
        }
    }

    async componentDidMount() {
        this.loadLocalStorageData();
        // Get Selected USER details
        const userId = localStorage.getItem("selectedUserId");
        const options = {
            url: API_URL.BACKEND_SERVICE_API_URL+`/user/get-user-details/${userId}`,
            method: "GET",
            headers: {
                authorization: localStorage.getItem('token')
            }
        };
        let response = await request(options);
        this.setState({ selectedUser: response.payload });
    }

    loadLocalStorageData = async () => {
        //Store roles
        let roles = localStorage.getItem('roles');
        if (roles) {
            roles = JSON.parse(roles);
            this.setState({ roles });
        } else {
            let response = await this.props.listRoles();
            if(response.payload.length !== 0) {
                const rolest = JSON.stringify(response.payload);            
                localStorage.setItem('roles', rolest);
                roles = JSON.parse(rolest);
                this.setState({ roles });
            }
        }
    }

    onUpdate = async (e) => {
        e.preventDefault()
        try {
            const { selectedUser } = this.state
            let updateResponse = await this.props.updateUser({
                id: selectedUser.id,
                name: selectedUser.name,
                l_name: selectedUser.l_name, 
                user_name: selectedUser.user_name,
                email: selectedUser.email,
                pass_word: selectedUser.pass_word,
                phone_no: selectedUser.phone_no,
                role_id: selectedUser.role_id
            });
            toast.success(updateResponse.message, { autoClose: 10000 });
            window.open("/access-management/","_self");
        } catch (err) {
            toast.error(err.response.data.message, { autoClose: 10000 });
            console.log(err);
        }
    }

    handleInput = async (e) => {
        try {
            let tid = e.target.id;
            const { selectedUser } = this.state;
            switch(tid){
                case 'name':
                    const name = e.target.value;           
                    this.setState({
                        selectedUser: {
                            ...selectedUser,
                            name
                        }
                    });
                    break;
                case 'l_name':
                    const l_name = e.target.value;           
                    this.setState({
                        selectedUser: {
                            ...selectedUser,
                            l_name
                        }
                    });
                    break;
                case 'email':
                    const email = e.target.value;           
                    this.setState({
                        selectedUser: {
                            ...selectedUser,
                            email
                        }
                    });
                    const isEmailValid = !email || validEmail.test(email);
                    this.setState({ email: email, isEmailValid });
                    break;
                case 'user_name':
                    const user_name = e.target.value;           
                    this.setState({
                        selectedUser: {
                            ...selectedUser,
                            user_name
                        }
                    });
                    break;
                case 'pass_word':
                    const pass_word = e.target.value;
                    this.setState({
                        selectedUser: {
                            ...selectedUser,
                            pass_word
                        }
                    });
                    const isPasswordValid = !pass_word || validPassword.test(pass_word);
                    this.setState({ pass_word: pass_word, isPasswordValid });
                    break;
                case 'phone_no':
                    const phone_no = e.target.value;           
                    this.setState({
                        selectedUser: {
                            ...selectedUser,
                            phone_no
                        }
                    });
                    break;
                case 'roles':
                    const role_id = e.target.value;           
                    this.setState({
                        selectedUser: {
                            ...selectedUser,
                            role_id
                        }
                    });
                    break;
                default:
                    console.log("No Matching target found in handleInput");
                    break;
            }
        } catch (err) {
            console.log(err);
        }
    }

    render() {
        const { selectedUser, roles } = this.state;
        const inputFontStyle = { fontSize: '10px' }
        return (
            <Fragment>
                <div className='page-title'>
                    <Row>
                        <Col xs='6'>
                            <H3>Update User</H3>
                        </Col>
                        <Col xs='6'>
                            <ol className='breadcrumb'>
                                <li className='breadcrumb-item'>
                                    <Link to="/dashboard/">
                                        <SvgIcon iconId='stroke-home' />
                                    </Link>
                                </li>
                                <li className='breadcrumb-item'>
                                    <Link style={{"color":"#000"}} to="/access-management/">Access Management</Link>
                                </li>
                                <li className='breadcrumb-item active'>Update User</li>
                            </ol>
                        </Col>
                    </Row>
                </div>
                <ToastContainer />
                <Container fluid={true}>
                    <div className='edit-profile'>
                        <Row>
                            <Col>
                                <Form onSubmit={this.onUpdate}>
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col md="8">
                                                    <FormGroup className="mb-3"> 
                                                        <Label className="form-label">{FullName}<span style={{"color": "#bb131a"}}>*</span></Label>
                                                        <Input style={inputFontStyle} name="name" id="name" type="text" placeholder="First Name" required value={selectedUser.name || ''} onChange={this.handleInput}/>
                                                    </FormGroup>
                                                </Col>
                                                <Col md="8">
                                                    <FormGroup> 
                                                        <Label className="form-label">{Email}</Label>
                                                        <Input style={inputFontStyle} name="email" id="email" type="email" placeholder="Email" value={selectedUser.email || ''} onChange={this.handleInput} />
                                                        {this.state.isEmailValid ? <span></span> : <span style={{ color: "#F61C04" }}>Email Id is not valid.</span>}
                                                    </FormGroup>
                                                </Col>
                                                <Col md="8">
                                                    <FormGroup> 
                                                        <Label className="form-label">{Username}<span style={{"color": "#bb131a"}}>*</span></Label>
                                                        <Input style={inputFontStyle} name="user_name" id="user_name" type="text" required placeholder="User name" value={selectedUser.user_name || ''} onChange={this.handleInput} />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="8">
                                                    <FormGroup className="mb-3"> 
                                                        <Label className="form-label">{Password}<span style={{"color": "#bb131a"}}>*</span></Label>
                                                        <Input style={inputFontStyle} name="pass_word" id="pass_word" type="password" required placeholder="Enter Password" value={selectedUser.pass_word || ''} onChange={this.handleInput} />
                                                        {this.state.isPasswordValid ? <span></span> : <span style={{ color: "#F61C04" }}>Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, and one digit.</span>}
                                                    </FormGroup>
                                                </Col>
                                                <Col md="8">
                                                    <FormGroup> 
                                                        <Label className="form-label">Phone Number</Label>
                                                        <Input style={inputFontStyle} name="phone_no" id="phone_no" type="text" placeholder="Phone" value={selectedUser.phone_no || ''} onChange={this.handleInput} />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="8">
                                                    <FormGroup> 
                                                        <Label className="form-label">{Role}</Label>
                                                        <Input style={inputFontStyle} type="select" name="roles" id="roles" value={selectedUser.role_id || ''} onChange={this.handleInput}>
                                                            <option value=''>Select Role</option>
                                                            {roles.map((data, i) =>
                                                                <option key={i} value={data.id}>{data.role_name}</option>
                                                            )}
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                        <CardFooter className="text-center">
                                            <Btn attrBtn={{ color: "primary", type: "submit" }} >{Update}</Btn>&nbsp;&nbsp;&nbsp;
                                            <Link to="/access-management/">
                                                <Btn attrBtn={{ color: "primary", type: "reset" }} >{Cancel}</Btn>
                                            </Link>
                                        </CardFooter>
                                    </Card>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </Fragment>
        )
    }
}
const mapStateToProps = () => { return {} };

const mapDispatchToProps = {
    updateUser: userOperations.updateUser,
    listRoles: roleOperations.listRoles
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateUser)