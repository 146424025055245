import React from 'react';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pagesToShow = [];

  if (totalPages <= 10) {
    for (let i = 1; i <= totalPages; i++) {
      pagesToShow.push(i);
    }
  } else {
    const startPage = Math.max(1, currentPage - 5);
    const endPage = Math.min(totalPages, currentPage + 4);

    if (currentPage <= 5) {
      for (let i = 1; i <= 9; i++) {
        pagesToShow.push(i);
      }
    } else if (currentPage >= totalPages - 4) {
      for (let i = totalPages - 8; i <= totalPages; i++) {
        pagesToShow.push(i);
      }
    } else {
      for (let i = startPage; i <= endPage; i++) {
        pagesToShow.push(i);
      }
    }

    if (startPage > 1) {
      pagesToShow.unshift('...');
    }
    if (endPage < totalPages) {
      pagesToShow.push('...');
    }
  }

  return (
    <div className="center">
      <div className="pagination">
        <p onClick={() => onPageChange(currentPage - 1)} className={currentPage === 1 ? 'disabled' : ''}>Previous</p>
        {pagesToShow.map((page, index) => (
          <p key={index}
            onClick={() => {
              if (typeof page === 'number') {
                onPageChange(page);
              }
            }}
            className={page === currentPage ? 'active' : ''}>{typeof page === 'number' ? page : <span>&hellip;</span>}</p>
        ))}
        <p onClick={() => onPageChange(currentPage + 1)} className={currentPage === totalPages ? 'disabled' : ''}>Next</p>
      </div>
    </div>
  );
};

export default Pagination;