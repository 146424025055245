import * as types from "./types";
import * as API_URL from "../../../env";

export const addParkingPlaza = (payload) => ({
    type: types.INSERT,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/parking-plaza/add-parking-plaza",
        method: "POST",
        body: payload
    },
});

export const listParkingPlaza = (payload) => ({
    type: types.FETCH_LIST,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/parking-plaza/list-parking-plaza",
        method: "POST",
        body: payload
    },
});

export const updateParkingPlaza = (payload) => ({
    type: types.UPDATE,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/parking-plaza/update-parking-plaza",
        method: "PUT",
        body: payload
    },
});

export const activeInActiveParkingPlaza = (payload) => ({
    type: types.UPDATE,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/parking-plaza/active-inactive-parking-plaza",
        method: "PUT",
        body: payload
    },
});