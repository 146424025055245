import Signin from '../Pages/Auth/Signin/Signin';
import Signup from '../Pages/Auth/Signup/Signup';
import Logout from '../Pages/Auth/Logout/Logout';
import ForgotPassword from '../Pages/Auth/ForgotPassword/ForgotPassword';
import ConfirmOTP from '../Pages/Auth/ConfirmOTP/ConfirmOTP';
import ResetPassword from '../Pages/Auth/ResetPassword/ResetPassword';

export const authRoutes = [
  { path: "/login", Component: <Signin /> },
  { path: "/signup", Component: <Signup /> },
  { path: "/logout", Component: <Logout /> },
  { path: "/forgot-password", Component: <ForgotPassword /> },
  { path: "/confirm-otp", Component: <ConfirmOTP /> },
  { path: "/reset-password", Component: <ResetPassword /> }
];