import { combineReducers } from "redux";
import * as types from "./types";
import { createReducer } from "../../utils";

const listReducer = createReducer([])({
    [types.INSERT_USER_COMPLETED]: (state, action) => {
        const event = action.payload.payload;
        state.push(event)
        return state.slice()
    },
    [types.FETCH_USER_LIST_COMPLETED]: (state, action) => {
        return action.payload.payload
    },
    [types.UPDATE_USER_COMPLETED]: (state, action) => {
        const user = action.payload.payload
        return state.map(e => e.id === user.id ? user : e)
    },
    [types.DELETE_USER_COMPLETED]: (state, action) => {
        return action.payload.payload
    }
});

export default combineReducers({
    list: listReducer
});