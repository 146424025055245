import * as types from "./types";
import * as API_URL from "../../../env";

export const realTimeReport = (payload) => ({
    type: types.FETCH_LIST,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/report/real-time-report",
        method: "POST",
        body: payload
    },
});

export const scanRfidTag = (payload) => ({
    type: types.FETCH_LIST,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/rfidTag/scan-rfid-tag",
        method: "POST",
        body: payload
    },
});

export const getDebugModeError = (payload) => ({
    type: types.FETCH_LIST,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/public/get-logs-file",
        method: "GET",
        body: payload
    },
});

export const logsDetailsExcel = (payload) => ({
    type: types.FETCH_LIST,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/report/logs-details-excel",
        method: "GET",
        body: payload
    },
});