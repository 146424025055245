import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';
import { Container, Row, Col, Card, CardBody, CardHeader, CardFooter } from 'reactstrap';
import SvgIcon from '../../Components/Common/Component/SvgIcon';
import { H3, H6 } from '../../AbstractElements';
import excelImg from '../../assets/images/datatable/excel.png';
import { reportOperations } from '../../state/ducks/report';
import { parkingPlazaOperations } from '../../state/ducks/parking-plaza';
import Pagination from '../Pagination';
import '../../assets/scss/pagination.css';
import { formatDateAndTime, getParkingPlazaName } from '../../utils/helper';
import * as API_URL from "../../env.js";

class Logs extends React.Component {
    constructor() {
        super();
        this.state = {
            plaza: [],
            realTimeData: [],
            defaultSearchData: [],
            searchData: '',
            currentPage: 1,
            itemsPerPage: 10,
            sortedColumn: null,
            sortOrder: 'asc'
        }
    }

    async componentDidMount() {
        try {
            this.loadBase();
            this.loadLocalStorageData();
            this.timerID = setInterval(() => {
                if (window.location.pathname === "/logs/") {
                    this.loadBase();
                }
            }, 5000);
        } catch (err) {
            console.log(err);
        }
    }

    loadBase = async () => {
        try {
            const response = await this.props.realTimeReport();
            this.setState({ realTimeData: response.payload, defaultSearchData: response.payload });
        } catch (err) {
            console.log(err);
        }
    }

    loadLocalStorageData = async () => {
        // Store plaza
        localStorage.removeItem('plaza');
        let plaza = localStorage.getItem('plaza');
        if (plaza) {
            plaza = JSON.parse(plaza);
            this.setState({ plaza });
        } else {
            let response = await this.props.listParkingPlaza();
            if (response.payload.length !== 0) {
                const searchString = JSON.stringify(response.payload);
                localStorage.setItem('plaza', searchString);
                plaza = JSON.parse(searchString);
                this.setState({ plaza });
            }
        }
    }

    handleAddDisputeClick = (e) => {
        window.open("/dispute-managements/add-dispute/", "_self");
    }

    handlePageChange = (pageNumber) => {
        this.setState({ currentPage: pageNumber });
    };

    DefaultSearchChange = (e) => {
        const searchData = e.target.value;
        const defaultSearchData = this.state.realTimeData.filter(data => {
            const valuesToSearch = Object.values(data).filter(value => value !== null);
            return valuesToSearch.some(value =>
                String(value).toLowerCase().includes(searchData.toLowerCase())
            );
        });
        this.setState({ searchData, defaultSearchData });
    };

    handleTableSort = (column) => {
        const { defaultSearchData, sortedColumn, sortOrder } = this.state;
        // Toggle the sortOrder if the same column is clicked again
        const newSortOrder = column === sortedColumn && sortOrder === 'asc' ? 'desc' : 'asc';

        // Sort the data based on the selected column and sortOrder
        const sortedData = defaultSearchData.slice().sort((a, b) => {
            if (newSortOrder === 'asc') {
                return a[column] > b[column] ? 1 : -1;
            } else {
                return a[column] < b[column] ? 1 : -1;
            }
        });

        this.setState({
            defaultSearchData: sortedData,
            sortedColumn: column,
            sortOrder: newSortOrder,
        });
    };

    handleDownloadExcel = async () => {
        const response = await this.props.logsDetailsExcel();
        let fileName = response.payload.path;
        fileName = fileName.substring(fileName.lastIndexOf('/')+1);
        fetch(API_URL.API_URL + '/' + response.payload.path, {
            method: 'GET',
            headers: {
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
        })
        .then((response) => response.blob())
        .then((blob) => {
            // Create blob link to download
            const url = window.URL.createObjectURL(
                new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                fileName,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
        });
    };

    render() {
        const { defaultSearchData, currentPage, itemsPerPage, sortedColumn, sortOrder } = this.state;
        // For Pagination
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentData = defaultSearchData.slice(indexOfFirstItem, indexOfLastItem);
        const totalPages = Math.ceil(defaultSearchData.length / itemsPerPage);
        const buttonStyle = {
            "border": "none",
            "borderRadius": "3px",
            "background": "#e6e6ff",
            "color": "#000099",
            "fontSize": "10px"
        }
        return (
            <Fragment>
                <div className='page-title'>
                    <Row>
                        <Col xs='6'>
                            <H3></H3>
                        </Col>
                        <Col xs='6'>
                            <ol className='breadcrumb'>
                                <li className='breadcrumb-item'>
                                    <Link to="/dashboard/">
                                        <SvgIcon iconId='stroke-home' />
                                    </Link>
                                </li>
                                <li className='breadcrumb-item active'>Logs</li>
                            </ol>
                        </Col>
                    </Row>
                </div>
                <Container fluid={true}>
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader className='d-flex align-items-center justify-content-between'>
                                    <H6>Logs</H6>
                                    <img src={excelImg} onClick={this.handleDownloadExcel} width="35" height="35" alt='excel'></img>
                                </CardHeader>
                                <CardBody>
                                    <Table ref="tbl" striped hover responsive bordered id="data-table-zero" style={{ "overflow": "scroll" }}>
                                        <thead>
                                            <tr>
                                                <th onClick={() => this.handleTableSort('plaza_id')}>Plaza Name {sortedColumn === 'plaza_id' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                                                <th onClick={() => this.handleTableSort('gate_number')}>Gate No. {sortedColumn === 'gate_number' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                                                <th onClick={() => this.handleTableSort('lane_number')}>Lane No. {sortedColumn === 'lane_number' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                                                <th onClick={() => this.handleTableSort('vehicle_no')}>Vehicle No. {sortedColumn === 'vehicle_no' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                                                <th onClick={() => this.handleTableSort('entry_date_time')}>Entry Date & Time {sortedColumn === 'entry_date_time' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                                                <th onClick={() => this.handleTableSort('exit_date_time')}>Exit Date & Time {sortedColumn === 'exit_date_time' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                                                <th onClick={() => this.handleTableSort('reference_number')}>Reference No. {sortedColumn === 'reference_number' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                                                <th onClick={() => this.handleTableSort('npci_reference_number')}>Toll Reference No. {sortedColumn === 'npci_reference_number' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                                                <th onClick={() => this.handleTableSort('ski_data_ref_no')}>SKI data Reference No. {sortedColumn === 'ski_data_ref_no' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                                                <th onClick={() => this.handleTableSort('tariff_amount')}>Tariff Amount {sortedColumn === 'tariff_amount' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                                                <th>Payment Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentData.map((data, i) =>
                                                <tr key={i}>
                                                    <td>{getParkingPlazaName(data.plaza_id)}</td>
                                                    <td>{data.gate_number}</td>
                                                    <td>{data.exit_lane_number}</td>
                                                    <td>{data.vehicle_no}</td>
                                                    <td>{formatDateAndTime(data.entry_date_time)}</td>
                                                    <td>{formatDateAndTime(data.exit_date_time)}</td>
                                                    <td>{data.reference_number}</td>
                                                    <td>{data.npci_reference_number}</td>
                                                    <td>{data.ski_data_ref_no}</td>
                                                    <td>{data.tariff_amount}</td>
                                                    <td>{data.payment_status}</td>
                                                    <td><button type='button' style={buttonStyle} onClick={this.handleAddDisputeClick}>Add Dispute</button></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </CardBody>
                                <CardFooter>
                                    <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={this.handlePageChange} />
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}
const mapStateToProps = () => { return {} };

const mapDispatchToProps = {
    realTimeReport: reportOperations.realTimeReport,
    logsDetailsExcel: reportOperations.logsDetailsExcel,
    listParkingPlaza: parkingPlazaOperations.listParkingPlaza
};

export default connect(mapStateToProps, mapDispatchToProps)(Logs)