// dashbaord
import Dashboard from "../Pages/Dashboard/Dashboard";

// Access Management
import ChangePassword from '../Pages/Auth/ChangePassword';
import MyProfile from "../Pages/AccessManagement/MyProfile";
import AccessManagement from "../Pages/AccessManagement/AccessManagement";
import AddUser from "../Pages/AccessManagement/AddUser";
import UpdateUser from "../Pages/AccessManagement/UpdateUser";

// Parking Plaza
import ParkingPlaza from "../Pages/ParkingPlaza/ParkingPlaza";
import AddParkingPlaza from "../Pages/ParkingPlaza/AddParkingPlaza";
import EditParkingPlaza from "../Pages/ParkingPlaza/EditParkingPlaza";

// Gate
import Gate from "../Pages/Gate/Gate";
import AddGate from "../Pages/Gate/AddGate";
import EditGate from "../Pages/Gate/EditGate";

// Lane
import Lane from "../Pages/Lane/Lane";
import AddLane from "../Pages/Lane/AddLane";
import EditLane from "../Pages/Lane/EditLane";

// Logs
import Logs from "../Pages/Logs/Logs";

// DebugMode
import DebugMode from "../Pages/DebugMode/DebugMode"; 

// Report
import Report from "../Pages/Report/Report";
import RealTimeReport from "../Pages/Report/RealTimeReport";
import RevenueReport from "../Pages/Report/RevenueReport";
import DisputeReport from "../Pages/Report/DisputeReport";

// Dispute Management
import DisputeManagement from "../Pages/DisputeManagement/DisputeManagement";
import AddDispute from "../Pages/DisputeManagement/AddDispute";

export const routes = [
  { path: "/dashboard", Component: <Dashboard /> },
  { path: "/change-password", Component: <ChangePassword /> },
  { path: "/my-profile", Component: <MyProfile /> },

  { path: "/access-management", Component: <AccessManagement /> },
  { path: "/access-management/add-user", Component: <AddUser /> },
  { path: "/access-management/update-user", Component: <UpdateUser /> },

  { path: "/parking-plazas", Component: <ParkingPlaza /> },
  { path: "/parking-plazas/add-parking-plaza", Component: <AddParkingPlaza /> },
  { path: "/parking-plazas/edit-parking-plaza", Component: <EditParkingPlaza /> },

  { path: "/gate", Component: <Gate /> },
  { path: "/gate/add-gate", Component: <AddGate /> },
  { path: "/gate/edit-gate", Component: <EditGate /> },
  
  { path: "/lane", Component: <Lane /> },
  { path: "/lane/add-lane", Component: <AddLane /> },
  { path: "/lane/edit-lane", Component: <EditLane /> },

  { path: "/logs", Component: <Logs /> },

  { path: "/debug-mode", Component: <DebugMode /> },

  { path: "/reports", Component: <Report /> },
  { path: "/reports/real-time-report", Component: <RealTimeReport /> },
  { path: "/reports/revenue-report", Component: <RevenueReport /> },
  { path: "/reports/dispute-report", Component: <DisputeReport /> },

  { path: "/dispute-managements", Component: <DisputeManagement /> },
  { path: "/dispute-managements/add-dispute", Component: <AddDispute /> }
];