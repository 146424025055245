import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Form, FormGroup, Input, Label, Col } from 'reactstrap';
import { Btn, H5, P, Image } from '../../../AbstractElements.jsx';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import logoWhite from '../../../assets/images/logo/logo.png';
import { Email, Cancel } from '../../../Constant/index.jsx';
import { authOperations } from '../../../state/ducks/auth/index.js';
import { validEmail } from '../../validation.jsx';

class ForgotPassword extends React.Component {

  constructor() {
    super();
    this.state = {
      pass_word: '',
      isEmailValid: true
    }
  }

  onSendEmail = async (event) => {
    event.preventDefault();
    try {
      let response = await this.props.forgotPassword({
        email: this.state.email
      });
      this.setState({ email: '' });
      toast.success(response.message, { autoClose: 10000 });
      if (response.status === 200) {
        localStorage.setItem("selectedEmail", this.state.email);
        window.open("/confirm-otp/","_self");
      }
    } catch (err) {
      toast.error(err.response.data.message, { autoClose: 10000 });
      console.log(err);
    }
  }

  handleChange = async (e) => {
    try {
      let tid = e.target.id;
      switch(tid){
        case 'email':
          const email = e.target.value;
          const isEmailValid = !email || validEmail.test(email);
          this.setState({ email: email, isEmailValid });
          break;
        default:
          console.log("No Matching target found");
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    return (
      <Fragment>
        <ToastContainer />
        <div className='login-card'>
          <div>
            <div className='login-main'>
              <div>
                <Link className="logo" to="/signup/">
                  <Image attrImage={{ className: 'img-fluid for-light', src: logoWhite, alt: 'looginpage' }} />
                </Link>
              </div>
              <Form className='theme-form login-form' onSubmit={this.onSendEmail}>
                <H5>Forgot Password</H5>
                <P>Enter your email to Forgot Password</P>
                <Col>
                  <Label className='col-form-label m-0 pt-0'>{Email}</Label>
                  <Input type="email" name="email" id="email" required placeholder="Email" value={this.state.email || ''} onChange={this.handleChange} />
                  {this.state.isEmailValid ? <span></span> : <span style={{ color: "#F61C04" }}>Email Id is not valid.</span>}
                </Col>
                <FormGroup className="text-center mt-2">
                  <Btn attrBtn={{ color: 'primary', type: 'submit' }}>Send Email</Btn>&nbsp;&nbsp;&nbsp;
                  <Link to="/login/">
                    <Btn attrBtn={{ color: "primary", type: "reset" }} >{Cancel}</Btn>
                  </Link>
                </FormGroup>
              </Form>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };
}
const mapStateToProps = () => { return {} };

const mapDispatchToProps = { forgotPassword: authOperations.forgotPassword };

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)