import React, { Fragment } from 'react';
import { CardBody, Form, FormGroup, Input, Label, Row, Col, Container, CardFooter, Card } from 'reactstrap';
import { H3, Btn } from '../../../AbstractElements';
import { OldPassword, NewPassword, ConfirmPassword, Cancel } from '../../../Constant';
import SvgIcon from '../../../Components/Common/Component/SvgIcon';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { authOperations } from '../../../state/ducks/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class ChangePassword extends React.Component {

    onChange = async (event) => {
        event.preventDefault();
        try {
            let user = localStorage.getItem('user');
            if (user) {
                user = JSON.parse(user);
                this.setState({ user });
            }
            const userId = user.id;
            let insertResponse = await this.props.changePassword({
                id: userId,
                oldPassword: this.state.oldPassword,
                newPassword: this.state.NewPassword,
                confirmPassword: this.state.confirmPassword
            });
            this.setState({
                oldPassword: '',
                NewPassword: '',
                confirmPassword: ''
            });
            toast.success(insertResponse.message, { autoClose: 3000 });
            window.open("/dashboard/","_self");
        } catch (err) {
            toast.error(err.response.data.message, { autoClose: 3000 });
            console.log(err);
        }
    }

    render() {
        const inputFontStyle = { fontSize: '10px' }
        return (
            <Fragment>
                <div className='page-title'>
                    <Row>
                        <Col xs='6'><H3>Change Password</H3></Col>
                        <Col xs='6'>
                            <ol className='breadcrumb'>
                                <li className='breadcrumb-item'>
                                    <Link to="/dashboard/">
                                        <SvgIcon iconId='stroke-home' />
                                    </Link>
                                </li>
                                <li className='breadcrumb-item active'>Change Password</li>
                            </ol>
                        </Col>
                    </Row>
                </div>
                <ToastContainer />
                <Container fluid={true}>
                    <div className='edit-profile'>
                        <Row>
                            <Col>
                                <Form onSubmit={this.onChange}>
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col md="4">
                                                    <FormGroup className="mb-3"> 
                                                        <Label className="form-label">{OldPassword}</Label>
                                                        <Input style={inputFontStyle} type="password" placeholder="Old Password" onChange={(e) => this.setState({ oldPassword: e.target.value })} />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="4">
                                                    <FormGroup> 
                                                        <Label className="form-label">{NewPassword}</Label>
                                                        <Input style={inputFontStyle} type="password" placeholder="New Password" onChange={(e) => this.setState({ NewPassword: e.target.value })} />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="4">
                                                    <FormGroup> 
                                                        <Label className="form-label">{ConfirmPassword}</Label>
                                                        <Input style={inputFontStyle} type="password" placeholder="Confirm Password" onChange={(e) => this.setState({ confirmPassword: e.target.value })} />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                        <CardFooter className="text-center">
                                            <Col style={{"width": "92%"}}>
                                                <Btn attrBtn={{ color: "primary", type: "submit" }} >Change</Btn>&nbsp;&nbsp;&nbsp;
                                                <Link to="/dashboard/">
                                                    <Btn attrBtn={{ color: "primary", type: "reset" }} >{Cancel}</Btn>
                                                </Link>
                                            </Col>
                                        </CardFooter>
                                    </Card>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </Fragment>
        )
    }
}
const mapStateToProps = () => { return {} };

const mapDispatchToProps = {
    changePassword: authOperations.changePassword
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)