import * as types from "./types";
import * as API_URL from "../../../env";

export const addGate = (payload) => ({
    type: types.INSERT,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/gate/add-gate",
        method: "POST",
        body: payload
    },
});

export const listGate = (payload) => ({
    type: types.FETCH_LIST,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/gate/list-gate",
        method: "POST",
        body: payload
    },
});

export const updateGate = (payload) => ({
    type: types.UPDATE,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/gate/update-gate",
        method: "PUT",
        body: payload
    },
});

export const activeInActiveGate = (payload) => ({
    type: types.UPDATE,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/gate/active-inactive-gate",
        method: "PUT",
        body: payload
    },
});

export const listGateDetailsByPlazaId = (payload) => ({
    type: types.FETCH_LIST,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/gate/list-gate-details-by-plaza",
        method: "POST",
        body: payload
    },
});