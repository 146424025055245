import * as types from "./types";
import * as API_URL from "../../../env";

export const addLane = (payload) => ({
    type: types.INSERT,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/lane/add-lane",
        method: "POST",
        body: payload
    },
});

export const listLane = (payload) => ({
    type: types.FETCH_LIST,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/lane/list-lane",
        method: "POST",
        body: payload
    },
});

export const updateLane = (payload) => ({
    type: types.UPDATE,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/lane/update-lane",
        method: "PUT",
        body: payload
    },
});

export const activeInActiveLane = (payload) => ({
    type: types.UPDATE,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/lane/active-inactive-lane",
        method: "PUT",
        body: payload
    },
});

export const listLaneDetailsByGateId = (payload) => ({
    type: types.FETCH_LIST,
    meta: {
        async: true,
        blocking: true,
        path: API_URL.BACKEND_SERVICE_API_URL+"/lane/list-lane-details-by-gate",
        method: "POST",
        body: payload
    },
});