import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
  const [token] = useState(localStorage.getItem("token"));
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    setAuthenticated(JSON.parse(localStorage.getItem("authenticated")));
    localStorage.setItem("authenticated", authenticated);
    localStorage.setItem("token", token);
  }, []);
  return token || authenticated ? <Outlet /> : <Navigate exact to="/login/" />;
};

export default PrivateRoute;