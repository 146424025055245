import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { Search } from 'react-feather';
import { Container, Row, Col, Card, CardBody, CardHeader, CardFooter, Input, Form } from 'reactstrap';
import SvgIcon from '../../../Components/Common/Component/SvgIcon';
import { H3, Btn, H6 } from '../../../AbstractElements';
import { gateOperations } from '../../../state/ducks/gate';
import pauseIcon from '../.././../assets/images/datatable/pause.png';
import undoIcon from '../.././../assets/images/datatable/undo.png';
import editIcon from '../.././../assets/images/datatable/edit.png';
import Pagination from '../../Pagination';
import '../../../assets/scss/pagination.css';
import { formatDateAndTime, getParkingPlazaName, getRoleName } from '../../../utils/helper';
import { parkingPlazaOperations } from '../../../state/ducks/parking-plaza';

class Gate extends React.Component {
    constructor() {
        super();
        this.state = {
            role_name: '',
            plaza: [],
            GateData: [],
            defaultSearchData: [],
            searchData: '',
            currentPage: 1,
            itemsPerPage: 10,
            sortedColumn: null,
            sortOrder: 'asc'
        }
    }

    async componentDidMount() {
        try {
            this.loadLocalStorageData();
            // get user for role name
            let user = localStorage.getItem('user');
            if (user) {
                user = JSON.parse(user);
                this.setState({ user });
            }
            if (typeof user === 'undefined') {
            } else {
                const role_name = getRoleName(user.role_id);
                this.setState({ role_name: role_name });
            }

            const response = await this.props.listGate();
            this.setState({ GateData: response.payload, defaultSearchData: response.payload });
        } catch (err) {
            console.log(err);
        }
    }

    loadLocalStorageData = async () => {
        // Store plaza
        localStorage.removeItem('plaza');
        let plaza = localStorage.getItem('plaza');
        if (plaza) {
            plaza = JSON.parse(plaza);
            this.setState({ plaza });
        } else {
            let response = await this.props.listParkingPlaza();
            if (response.payload.length !== 0) {
                const searchString = JSON.stringify(response.payload);
                localStorage.setItem('plaza', searchString);
                plaza = JSON.parse(searchString);
                this.setState({ plaza });
            }
        }
    }

    handleEditClick = (e) => {
        localStorage.setItem("selectedGateId", e.target.getAttribute('data-test-id'));
        window.open("/gate/edit-gate/", "_self");
    }

    onPauseClick = async (e) => {
        const gateId = e.target.getAttribute('data-test-id');
        try {
            const deleteResponse = await this.props.activeInActiveGate({ id: gateId });
            const response = await this.props.listGate({ is_active: 1 });
            this.setState({ userData: response.payload, defaultSearchData: response.payload });
            if (response.payload[0].is_active === 0) {
                toast.error(deleteResponse.message, { autoClose: 3000 });
            } else {
                toast.success(deleteResponse.message, { autoClose: 3000 });
            }
        } catch (err) {
            toast.error(err.response.data.message, { autoClose: 3000 });
            console.log(err);
        }
    }

    handlePageChange = (pageNumber) => {
        this.setState({ currentPage: pageNumber });
    };

    DefaultSearchChange = (e) => {
        const searchData = e.target.value;
        const defaultSearchData = this.state.GateData.filter(data => {
            const valuesToSearch = Object.values(data).filter(value => value !== null);
            return valuesToSearch.some(value =>
                String(value).toLowerCase().includes(searchData.toLowerCase())
            );
        });
        this.setState({ searchData, defaultSearchData });
    };

    handleTableSort = (column) => {
        const { defaultSearchData, sortedColumn, sortOrder } = this.state;
        // Toggle the sortOrder if the same column is clicked again
        const newSortOrder = column === sortedColumn && sortOrder === 'asc' ? 'desc' : 'asc';

        // Sort the data based on the selected column and sortOrder
        const sortedData = defaultSearchData.slice().sort((a, b) => {
            if (newSortOrder === 'asc') {
                return a[column] > b[column] ? 1 : -1;
            } else {
                return a[column] < b[column] ? 1 : -1;
            }
        });

        this.setState({
            defaultSearchData: sortedData,
            sortedColumn: column,
            sortOrder: newSortOrder,
        });
    };

    render() {
        const { role_name, defaultSearchData, currentPage, searchData, itemsPerPage, sortedColumn, sortOrder } = this.state;

        // For Pagination
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentData = defaultSearchData.slice(indexOfFirstItem, indexOfLastItem);
        const totalPages = Math.ceil(defaultSearchData.length / itemsPerPage);
        const inputFontStyle = { fontSize: '10px' };
        const activeStyle = { "backgroundColor": "#ccffcc", "color": "#008000", "borderRadius": "15px", "fontSize": "10px", "textAlign": "center" }
        const inactiveStyle = { "backgroundColor": "#ffe6e6", "color": "#ff0000", "borderRadius": "15px", "fontSize": "10px", "textAlign": "center" }
        return (
            <Fragment>
                <div className='page-title'>
                    <Row>
                        <Col xs='6'>
                            <H3></H3>
                        </Col>
                        <Col xs='6'>
                            <ol className='breadcrumb'>
                                <li className='breadcrumb-item'>
                                    <Link to="/dashboard/">
                                        <SvgIcon iconId='stroke-home' />
                                    </Link>
                                </li>
                                <li className='breadcrumb-item active'>Gate</li>
                            </ol>
                        </Col>
                    </Row>
                </div>
                <ToastContainer />
                <Container fluid={true}>
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader className='d-flex align-items-center justify-content-between'>
                                    <H6>Gate</H6>
                                    {role_name === 'Admin' || role_name === 'System Administrator' ? <span></span> : <span>
                                        <div className='d-flex align-items-center gap-3'>
                                            <Link to="/gate/add-gate/">
                                                <Btn attrBtn={{ color: 'primary d-flex align-items-center' }}>Add Gate</Btn>
                                            </Link>
                                        </div>
                                    </span>}
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <Row>
                                            <Col md='9' className='mb-2'></Col>
                                            <Col md='3' className='mb-2'>
                                                <div className="job-filter">
                                                    <div className="faq-form">
                                                        <Input style={inputFontStyle} type="text" placeholder="Search" value={searchData} onChange={this.DefaultSearchChange} />
                                                        <Search className="search-icon" />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                    <Table ref="tbl" striped hover responsive bordered id="data-table-zero">
                                        <thead>
                                            <tr>
                                                <th onClick={() => this.handleTableSort('plaza_id')}>Parking Plaza Name {sortedColumn === 'plaza_id' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                                                <th onClick={() => this.handleTableSort('no_of_gate')}>No of Gate {sortedColumn === 'no_of_gate' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                                                <th onClick={() => this.handleTableSort('created_date')}>Created Date {sortedColumn === 'created_date' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                                                <th onClick={() => this.handleTableSort('is_active')}>Status {sortedColumn === 'is_active' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                                                {role_name === 'Admin' || role_name === 'System Administrator' ? <span style={{ "visibility": "hidden" }}></span> : <th>Actions</th>}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentData.map((data, i) =>
                                                <tr key={i}>
                                                    <td>{getParkingPlazaName(data.plaza_id)}</td>
                                                    <td>{data.no_of_gate}</td>
                                                    <td>{formatDateAndTime(data.created_date)}</td>
                                                    {data.is_active ? <td><p style={activeStyle}>Active</p></td> : <td><p style={inactiveStyle}>Inactive</p></td>}
                                                    {role_name === 'Admin' || role_name === 'System Administrator' ? <span style={{ "visibility": "hidden" }}></span> : <td>
                                                        {data.is_active ?
                                                            <span>
                                                                <img src={editIcon} alt='edit' height={20} width={20} data-test-id={data.id} onClick={this.handleEditClick}></img>&nbsp;&nbsp;
                                                                <img src={pauseIcon} alt='pause' height={17} width={17} data-test-id={data.id} onClick={this.onPauseClick}></img>
                                                            </span>
                                                            : <img src={undoIcon} alt='undo' height={18} width={18} data-test-id={data.id} onClick={this.onPauseClick}></img>
                                                        }
                                                    </td>}
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </CardBody>
                                <CardFooter>
                                    <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={this.handlePageChange} />
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}
const mapStateToProps = () => { return {} };

const mapDispatchToProps = {
    listGate: gateOperations.listGate,
    activeInActiveGate: gateOperations.activeInActiveGate,
    listParkingPlaza: parkingPlazaOperations.listParkingPlaza
};

export default connect(mapStateToProps, mapDispatchToProps)(Gate)