export const INSERT = "lane/INSERT";
export const INSERT_COMPLETED = "lane/INSERT_COMPLETED";
export const INSERT_FAILED = "lane/INSERT_FAILED";

export const FETCH_LIST = "lane/FETCH_LIST";
export const FETCH_LIST_COMPLETED = "lane/FETCH_LIST_COMPLETED";
export const FETCH_LIST_FAILED = "lane/FETCH_LIST_FAILED";

export const UPDATE = "lane/UPDATE";
export const UPDATE_COMPLETED = "lane/UPDATE_COMPLETED";
export const UPDATE_FAILED = "lane/UPDATE_FAILED";