import React from 'react';
import Routers from './Route';
import FilterProvider from './_helper/Ecommerce/Filter/FilterProvider';
import WishListProvider from './_helper/Ecommerce/Wishlist/WishlistProvider';
import AnimationThemeProvider from './_helper/AnimationTheme/AnimationThemeProvider';
import CustomizerProvider from './_helper/Customizer/CustomizerProvider';
// import ErrorBoundary from './ErrorBoundary';

const App = () => (
  <div className='App'>
    <CustomizerProvider>
      <WishListProvider>
        <FilterProvider>
          <AnimationThemeProvider>
            {/* <ErrorBoundary> */}
            <Routers />
            {/* </ErrorBoundary> */}
          </AnimationThemeProvider>
        </FilterProvider>
      </WishListProvider>
    </CustomizerProvider>
  </div>
);

export default App;