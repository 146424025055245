import React, { Component, Fragment } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import "../../../assets/scss/progress.tracker.css";
import '../../../assets/scss/table.css';
import "../../../assets/scss/toggle.css";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import * as API_URL from "../../../env.js";
import request from "../../../utils/request.js";
import SvgIcon from '../../../Components/Common/Component/SvgIcon.jsx';
import { H5, Btn } from "../../../AbstractElements.jsx";
import { CardBody, CardHeader, Form, FormGroup, Label, Input, Row, Col, Card, Container, CardFooter } from 'reactstrap';
import { Cancel, Update } from "../../../Constant/index.jsx";
import { parkingPlazaOperations } from '../../../state/ducks/parking-plaza/index.js';
import { gateOperations } from '../../../state/ducks/gate/index.js';
import { masterOperations } from "../../../state/ducks/master/index.js";

class EditGate extends Component {

    constructor(props) {
        super(props);
        const gateStatus = 0;
        this.state = {
            selectedGate: {},
            plaza: [],
            floor: [],
            plaza_id: 0,
            gateData: [],
            GateDataByPlaza: [],
            gate_status: Array.from({ length: gateStatus }, () => ({ gate_status: false }))
        };
    }

    async componentDidMount() {
        this.loadLocalStorageData();
        const id = localStorage.getItem("selectedGateId");
        const options = {
            url: API_URL.BACKEND_SERVICE_API_URL + `/gate/get-gate-details/${id}`,
            method: "GET",
            headers: {
                authorization: localStorage.getItem('token')
            }
        };
        let response = await request(options);
        this.setState({ selectedGate: response.payload });

        // Get Gate Details By PlazaId
        this.setState({ plaza_id: response.payload.plaza_id });
        const responseGateByPlaza = await this.props.listGateDetailsByPlazaId({ plazaId: response.payload.plaza_id });
        this.setState({ GateDataByPlaza: responseGateByPlaza.payload });

        const { gateStatus } = this.state;
        this.setState({
            gate_status: Array.from({ length: gateStatus }, () => ({ gate_status: false }))
        });
    }

    loadLocalStorageData = async () => {
        // Store plaza
        localStorage.removeItem('plaza');
        let plaza = localStorage.getItem('plaza');
        if (plaza) {
            plaza = JSON.parse(plaza);
            this.setState({ plaza });
        } else {
            let response = await this.props.listParkingPlaza();
            if (response.payload.length !== 0) {
                const searchString = JSON.stringify(response.payload);
                localStorage.setItem('plaza', searchString);
                plaza = JSON.parse(searchString);
                this.setState({ plaza });
            }
        }

        // Store Floor
        let floor = localStorage.getItem('floor');
        if (floor) {
            floor = JSON.parse(floor);
            this.setState({ floor });
        } else {
            let response = await this.props.floorList();
            if (response.payload.length !== 0) {
                const searchString = JSON.stringify(response.payload);
                localStorage.setItem('floor', searchString);
                floor = JSON.parse(searchString);
                this.setState({ floor });
            }
        }
    }

    handleInput = async (e) => {
        try {
            let tid = e.target.id;
            const { selectedGate } = this.state;
            switch (tid) {
                case 'plaza':
                    const plaza_id = e.target.value;
                    this.setState({
                        selectedGate: {
                            ...selectedGate,
                            plaza_id
                        }
                    });
                    this.setState({ plaza_id: e.target.value });
                    const response = await this.props.listGateDetailsByPlazaId({ plazaId: e.target.value });
                    this.setState({ GateDataByPlaza: response.payload });
                    break;
                case 'description':
                    const description = e.target.value;
                    this.setState({
                        selectedGate: {
                            ...selectedGate,
                            description
                        }
                    });
                    break;
                default:
                    console.log("No Matching target found in handleInput");
                    break;
            }
        } catch (err) {
            console.log(err);
        }
    }

    handleInputChange = (e, index, field) => {
        const targetId = e.target.id;
        this.setState((prevState) => {
            const newGates = prevState.selectedGate.gates.map((gate, i) => {
                if (i === index) {
                    switch (targetId) {
                        case "gate_name":
                            return { ...gate, gate_name: e.target.value };
                        case "gate_id":
                            return { ...gate, gate_id: e.target.value };
                        case "gate_number":
                            return { ...gate, gate_number: e.target.value };
                        case "gate_status":
                            return { ...gate, gate_status: !gate.gate_status };
                        case "floor_id":
                            return { ...gate, floor_id: e.target.value };
                        default:
                            return gate;
                    }
                }
                return gate;
            });
            return {
                selectedGate: {
                    ...prevState.selectedGate,
                    gates: newGates,
                },
            };
        });
    };

    // multiple textbox create for gate
    handleNoOfGateChange = (e) => {
        e.preventDefault();
        const newGateCount = parseInt(e.target.value, 10) || 0;
        this.setState((prevState) => {
            const existingGates = prevState.selectedGate.gates.slice(0, newGateCount);
            const newGates = Array.from({ length: newGateCount - existingGates.length }, (_, i) => ({
                gate_name: '',
                gate_id: '',
                gate_number: '',
                floor_id: '',
                gate_status: ''
            }));
            return {
                selectedGate: {
                    ...prevState.selectedGate,
                    no_of_gate: newGateCount,
                    gates: [...existingGates, ...newGates],
                },
            };
        });
    };

    handlePlusButtonClick = (e) => {
        e.preventDefault();
        this.setState((prevState) => {
            const newGateCount = prevState.selectedGate.no_of_gate + 1;
            const newGates = [...prevState.selectedGate.gates, {
                gate_name: '',
                gate_id: '',
                gate_number: '',
                floor_id: '',
                gate_status: ''
            }];
            return {
                selectedGate: {
                    ...prevState.selectedGate,
                    no_of_gate: newGateCount,
                    gates: newGates,
                },
            };
        });
    };

    handleMinusButtonClick = (e) => {
        e.preventDefault();
        this.setState((prevState) => {
            const newGateCount = prevState.selectedGate.no_of_gate - 1;
            const newGates = [...prevState.selectedGate.gates, {
                gate_name: '',
                gate_id: '',
                gate_number: '',
                floor_id: '',
                gate_status: ''
            }];
            return {
                selectedGate: {
                    ...prevState.selectedGate,
                    no_of_gate: newGateCount,
                    gates: newGates,
                },
            };
        });
    }

    handleCheckboxChange = (checkboxId) => {
        this.setState((prevState) => {
            const updatedCheckboxes = { ...prevState.checkboxes };
            updatedCheckboxes[checkboxId] = !updatedCheckboxes[checkboxId];
            return { checkboxes: updatedCheckboxes };
        });
    };

    gateInformationInputField = () => {
        const { selectedGate, floor } = this.state;
        const inputFontStyle = { fontSize: '10px' };
        const plusStyle = { border: "#48bb78 2px solid", backgroundColor: "#48bb78", color: "#fff", fontWeight: "bold" };
        const minusStyle = { border: "#ff6666 2px solid", backgroundColor: "#ff6666", color: "#fff", fontWeight: "bold" };
        const gateTextbox = [];

        for (let i = 0; i < selectedGate.no_of_gate; i++) {
            if (selectedGate.gates && selectedGate.gates[i]) {
                gateTextbox.push(
                    <Row key={i} style={{ "marginBottom": "5px", "background": "#d9d9d9", "borderRadius": "10px" }}>
                        <p style={{ "fontSize": "12px", "color": "#000", "fontWeight": "bold" }}>Gate {i + 1} Information</p>
                        <Col md="4">
                            <FormGroup>
                                <Label>Gate Name</Label>
                                <Input
                                    type="text"
                                    style={inputFontStyle}
                                    placeholder="Enter Gate Name"
                                    id="gate_name"
                                    value={selectedGate.gates[i].gate_name || ''}
                                    onChange={(e) => this.handleInputChange(e, i, 'gate_name')}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <Label>Gate ID</Label>
                                <Input
                                    type="text"
                                    style={inputFontStyle}
                                    placeholder="Enter Gate ID"
                                    id="gate_id"
                                    value={selectedGate.gates[i].gate_id || ''}
                                    onChange={(e) => this.handleInputChange(e, i, 'gate_id')}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <Label>Gate Number</Label>
                                <Input
                                    type="text"
                                    style={inputFontStyle}
                                    placeholder="Enter Gate Number"
                                    id="gate_number"
                                    value={selectedGate.gates[i].gate_number || ''}
                                    onChange={(e) => this.handleInputChange(e, i, 'gate_number')}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="4">
                            <FormGroup>
                                <Label>No of Floor Associated</Label>
                                <Input style={inputFontStyle} type="select" id="floor_id"
                                    value={selectedGate.gates[i].floor_id || ''} onChange={(e) => this.handleInputChange(e, i, 'floor_id')}>
                                    <option>Select Floor</option>
                                    {floor.map((data, j) =>
                                        <option key={j} value={data.name}>{data.name}</option>
                                    )}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md="2">
                            <FormGroup>
                                <Label>Gate Status</Label>
                                <label className="switch">
                                    <Input
                                        type="checkbox"
                                        id="gate_status"
                                        checked={selectedGate.gates[i].gate_status || false}
                                        onChange={(e) => this.handleInputChange(e, i, 'gate_status')}
                                    />
                                    <span className="slider"></span>
                                </label>
                            </FormGroup>
                        </Col><Col md="2"></Col>
                        <Col style={{ "marginTop": "24px" }}>
                            <button type="button" style={plusStyle} onClick={this.handlePlusButtonClick}>+</button>&nbsp;&nbsp;
                            <button type="button" style={minusStyle} onClick={this.handleMinusButtonClick}>-</button>
                        </Col>
                    </Row>
                );
            }
        }
        return gateTextbox;
    };

    onUpdate = async (e) => {
        e.preventDefault()
        try {
            const { selectedGate } = this.state
            let response = await this.props.updateGate({
                id: selectedGate.id,
                plaza_id: selectedGate.plaza_id,
                no_of_gate: selectedGate.no_of_gate,
                gates: selectedGate.gates,
                description: selectedGate.description
            });
            toast.success(response.message, { autoClose: 10000 });
            window.open("/gate/", "_self");
        } catch (err) {
            toast.error(err.response.data.message, { autoClose: 10000 });
            console.log(err);
        }
    }

    render() {
        const { plaza_id, selectedGate, plaza, GateDataByPlaza } = this.state;
        const inputFontStyle = { fontSize: '10px' };
        const activeStyle = { "width": "80px", "backgroundColor": "#ccffcc", "color": "#008000", "borderRadius": "15px", "fontSize": "10px", "textAlign": "center" }
        const inactiveStyle = { "width": "80px", "backgroundColor": "#ffe6e6", "color": "#ff0000", "borderRadius": "15px", "fontSize": "10px", "textAlign": "center" }
        return (
            <Fragment>
                <div className='page-title'>
                    <Row>
                        <Col xs='6'></Col>
                        <Col xs='6'>
                            <ol className='breadcrumb'>
                                <li className='breadcrumb-item'>
                                    <Link to="/dashboard/">
                                        <SvgIcon iconId='stroke-home' />
                                    </Link>
                                </li>
                                <li className='breadcrumb-item'>
                                    <Link style={{ "color": "#000" }} to="/gate/">Gate</Link>
                                </li>
                                <li className='breadcrumb-item active'>Edit Gate</li>
                            </ol>
                        </Col>
                    </Row>
                </div>
                <ToastContainer />
                <Container fluid={true} className='search-page'>
                    <Row>
                        <Col sm='12'>
                            <Form onSubmit={this.onUpdate}>
                                <Card>
                                    <CardHeader className='d-flex align-items-center justify-content-between'>
                                        <H5>Edit Gate</H5>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col md="1"></Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label>Select Parking Plaza</Label>
                                                    <Input style={inputFontStyle} type="select" id="plaza" value={selectedGate.plaza_id || ''} onChange={this.handleInput}>
                                                        <option>Select Parking Plaza</option>
                                                        {plaza.map((data, i) =>
                                                            <option key={i} value={data.id}>{data.p_name}</option>
                                                        )}
                                                    </Input>
                                                </FormGroup>
                                            </Col><Col md="7"></Col><Col md="1"></Col>
                                            {plaza_id !== 0 ?
                                                <Col md="10">
                                                    <Label>Available Gate</Label>
                                                    <table id="customers">
                                                        <thead>
                                                            <tr>
                                                                <th>Gate Name</th>
                                                                <th>Gate ID</th>
                                                                <th>Gate Number</th>
                                                                <th>Gate Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {GateDataByPlaza.map((data, i) =>
                                                                <tr key={i}>
                                                                    <td>{data.gate_name}</td>
                                                                    <td>{data.gate_id}</td>
                                                                    <td>{data.gate_number}</td>
                                                                    {data.gate_status ? <td><p style={activeStyle}>Active</p></td> : <td><p style={inactiveStyle}>Inactive</p></td>}
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </Col> : <span></span>}
                                        </Row>
                                        <Row>
                                            <Col md="1"></Col>
                                            <Col md="10">
                                                <FormGroup>
                                                    <Label>No of Gate</Label>
                                                    <Input
                                                        type="number"
                                                        style={inputFontStyle}
                                                        placeholder="Enter No of Gate"
                                                        id="no_of_gate"
                                                        value={selectedGate.no_of_gate || ''}
                                                        onChange={this.handleNoOfGateChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {selectedGate.no_of_gate === 0 ? <span></span> :
                                            <Row style={{ "marginLeft": "100px", "marginRight": "80px" }}>
                                                {this.gateInformationInputField()}
                                            </Row>
                                        }
                                        <Row>
                                            <Col md="1"></Col>
                                            <Col md="10">
                                                <FormGroup className="mb-3">
                                                    <Label className="form-label">Description</Label>
                                                    <Input style={inputFontStyle} type="textarea" placeholder="Enter Description" id="description" value={selectedGate.description || ''} onChange={this.handleInput} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter className="text-center">
                                        <Btn attrBtn={{ color: "primary", type: "submit" }} >{Update}</Btn>&nbsp;&nbsp;&nbsp;
                                        <Link to="/gate/">
                                            <Btn attrBtn={{ color: "primary", type: "reset" }} >{Cancel}</Btn>
                                        </Link>
                                    </CardFooter>
                                </Card>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}
const mapStateToProps = () => { return {} };

const mapDispatchToProps = {
    updateGate: gateOperations.updateGate,
    listParkingPlaza: parkingPlazaOperations.listParkingPlaza,
    listGateDetailsByPlazaId: gateOperations.listGateDetailsByPlazaId,
    floorList: masterOperations.floorList
};

export default connect(mapStateToProps, mapDispatchToProps)(EditGate)