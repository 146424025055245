import React, { Fragment, useState } from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { Btn, H4, P, Image } from "../../../AbstractElements";
import { Password, SignIn, Username } from "../../../Constant";
import { connect } from "react-redux";
import { get } from "lodash";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { login, initializeSession, authenticated, fetchListMenusForRole } from "../../../state/ducks/auth/operations";
import logoWhite from '../../../assets/images/logo/logo.png';

const Signin = (props) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [togglePassword, setTogglePassword] = useState(false);
  const history = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'username') {
      setUsername(value);
    } else if (name === 'password') {
      setPassword(value);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      let { user, token } = await props.login({
        username,
        pass_word: password,
        device_type:"Web"
      });
      props.initializeSession({ user, token });
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('token', token);

      // Store Menus
      const menuResponse = await props.fetchListMenusForRole({ role_id: user.role_id });
      const menus = get(menuResponse, 'payload.menus', []);
      localStorage.setItem('menus', JSON.stringify(menus));
      props.authenticated();

      toast.success('Login  Successfully', { autoClose: 10000 });
      history("/dashboard/");
    } catch (error) {
      console.log(error);
      if(error.response.data.message === 'Server Error'){
        toast.error("Something went wrong.");
      } else {
        toast.error(error.response.data.message, { autoClose: 10000 });
      }
    }
  };

  return (
    <Fragment>
      <ToastContainer />
      <div className='login-card'>
        <div>
          <div className='login-main'>
            <div>
              <Link className="logo" to="/login/">
                <Image attrImage={{ className: 'img-fluid for-light', src: logoWhite, alt: 'looginpage' }} />
              </Link>
            </div>
            <Form className="theme-form" onSubmit={onSubmit}>
              <H4>Welcome Back</H4>
              <P>{"Log in your account"}</P>
              <FormGroup>
                <Label className="col-form-label">{Username}</Label>
                <Input type="text" name="username" placeholder="Enter your Username" onChange={handleChange} value={username} />
              </FormGroup>
              <FormGroup className="position-relative">
                <Label className="col-form-label">{Password}</Label>
                <div className="position-relative">
                  <Input type={togglePassword ? "text" : "password"} name="password" placeholder="Enter your Password" value={password} onChange={handleChange} />
                  <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}>
                    <span className={togglePassword ? "" : "show"}></span>
                  </div>
                </div>
              </FormGroup>
              <div className="position-relative form-group">
                <div className="checkbox">
                  <Input id="checkbox1" type="checkbox" />
                  <Label for="checkbox1">Remember me</Label>
                </div>
                <Btn attrBtn={{ color: "primary", className: "d-block w-100"}} type="submit">{SignIn}</Btn>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
const mapStateToProps = () => {
  return {}
};

const mapDispatchToProps = {
  login,
  initializeSession,
  authenticated,
  fetchListMenusForRole
};
export default connect(mapStateToProps, mapDispatchToProps)(Signin);