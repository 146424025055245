import React, { Component, Fragment } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import "../../../assets/scss/progress.tracker.css";
import "../../../assets/scss/toggle.css";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import SvgIcon from '../../../Components/Common/Component/SvgIcon.jsx';
import { H5, Btn } from "../../../AbstractElements.jsx";
import { CardBody, CardHeader, Form, FormGroup, Label, Input, Row, Col, Card, Container, CardFooter } from 'reactstrap';
import { Cancel, Save } from "../../../Constant/index.jsx";
import { parkingPlazaOperations } from '../../../state/ducks/parking-plaza/index.js';
import { laneOperations } from '../../../state/ducks/lane';
import { gateOperations } from '../../../state/ducks/gate';

class AddLane extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: [{ id: 1, lane_name: '', lane_id: '', lane_number: '', lane_status: false, rfid_module_ip_address: '', rfid_module_ip_number: '', rfid_module_ip_password: '', rfid_module_url: '', camera_address: '', camera_id: '', camera_password: '', camera_url: '' }],
            numFields: 0,
            plaza: [],
            gate: [],
            gateList: [],
            listLaneDetailsByGateId: [],
            laneData: [],
            is_available_lane: 0
        };
    }

    async componentDidMount() {
        try {
            this.loadLocalStorageData();
            const { numFields } = this.state;
            this.setState({ lane_status: Array.from({ length: numFields }, () => ({ lane_status: false })) });
        } catch (err) {
            console.log(err);
        }
    }

    loadLocalStorageData = async () => {
        // Store plaza
        localStorage.removeItem('plaza');
        let plaza = localStorage.getItem('plaza');
        if (plaza) {
            plaza = JSON.parse(plaza);
            this.setState({ plaza });
        } else {
            let response = await this.props.listParkingPlaza();
            if (response.payload.length !== 0) {
                const searchString = JSON.stringify(response.payload);
                localStorage.setItem('plaza', searchString);
                plaza = JSON.parse(searchString);
                this.setState({ plaza });
            }
        }
    }

    handleChange = async (e) => {
        try {
            let tid = e.target.id;
            switch (tid) {
                case 'plaza':
                    this.setState({ plaza_id: e.target.value });
                    let response = await this.props.listGateDetailsByPlazaId({ plazaId: e.target.value });
                    this.setState({ gateList: response.payload });
                    response.payload.map((data) => this.setState({ gate_id: data.id }) );
                    break;
                case 'gate':
                    this.setState({ gate_name: e.target.value });
                    let laneResponse = await this.props.listLaneDetailsByGateId({ gateName: e.target.value });
                    if (laneResponse.payload.length === 0) {
                        this.setState({ is_available_lane: 0 });
                    } else {
                        this.setState({ listLaneDetailsByGateId: laneResponse.payload, is_available_lane: 1 });
                    }
                    break;
                default:
                    console.log("No Matching target found");
                    break;
            }
        } catch (err) {
            console.log(err);
        }
    };

    handleInputChange = (event, id, fieldName) => {
        const { value, type, checked } = event.target;
        const fieldValue = type === 'checkbox' ? checked : value;
        this.setState((prevState) => ({
            fields: prevState.fields.map((field) =>
                field.id === id ? { ...field, [fieldName]: fieldValue } : field
            ),
        }));
    };

    handleAddFields = () => {
        this.setState((prevState) => ({ numFields: prevState.numFields + 1 }));
        this.setState((prevState) => {
            const { fields } = prevState;
            const newField = {
                id: fields.length + 1,
                lane_name: '',
                lane_id: '',
                lane_number: '',
                lane_status: false,
                rfid_module_ip_address: '',
                rfid_module_ip_number: '',
                rfid_module_ip_password: '',
                rfid_module_url: '',
                camera_address: '',
                camera_id: '',
                camera_password: '',
                camera_url: ''
            };
            return {
                fields: [...fields, newField],
            };
        });
    };

    handleRemoveFields = () => {
        this.setState((prevState) => ({ numFields: prevState.numFields - 1 }));
        this.setState((prevState) => {
            const { fields } = prevState;
            const newField = {
                id: fields.length - 1,
                lane_name: '',
                lane_id: '',
                lane_number: '',
                lane_status: false,
                rfid_module_ip_address: '',
                rfid_module_ip_number: '',
                rfid_module_ip_password: '',
                rfid_module_url: '',
                camera_address: '',
                camera_id: '',
                camera_password: '',
                camera_url: ''
            };
            return {
                fields: [...fields, newField],
            };
        });
    };

    handleNumFieldsChange = (event) => {
        const numFields = parseInt(event.target.value, 10) || 0;
        this.setState((prevState) => {
            const currentFields = prevState.fields.slice(0, numFields);
            const newFields = Array.from({ length: numFields - currentFields.length }, (_, index) => ({
                id: currentFields.length + index + 1,
                lane_name: '',
                lane_id: '',
                lane_number: '',
                lane_status: false,
                rfid_module_ip_address: '',
                rfid_module_ip_number: '',
                rfid_module_ip_password: '',
                rfid_module_url: '',
                camera_address: '',
                camera_id: '',
                camera_password: '',
                camera_url: ''
            }));
            return {
                numFields,
                fields: [...currentFields, ...newFields],
            };
        });
    };

    dynamicInputField = () => {
        const { numFields, fields } = this.state;
        const inputFontStyle = { fontSize: '10px' };
        const plusStyle = { border: "#48bb78 2px solid", backgroundColor: "#48bb78", color: "#fff", fontWeight: "bold" };
        const minusStyle = { border: "#ff6666 2px solid", backgroundColor: "#ff6666", color: "#fff", fontWeight: "bold" };
        const laneTextbox = [];
        for (let i = 0; i < numFields; i++) {
            const field = fields[i] || {};
            laneTextbox.push(
                <Row key={i} className="mb-1">
                    <Col md="1"></Col>
                    <Col md="11">
                        <Label style={{ "color": "#000" }}>Lane {i + 1} Information</Label>
                    </Col>
                    <Col md="1"></Col>
                    <Col md="9" style={{ "marginLeft": "4%", "background": "#d9d9d9", "borderRadius": "10px" }}>
                        <Row>
                            <Col md="3">
                                <FormGroup>
                                    <Label>Lane Name</Label>
                                    <Input
                                        type="text"
                                        style={inputFontStyle}
                                        placeholder="Enter Lane Name"
                                        value={field.lane_name}
                                        onChange={(e) => this.handleInputChange(e, field.id, 'lane_name')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <FormGroup>
                                    <Label>Lane ID</Label>
                                    <Input
                                        type="text"
                                        style={inputFontStyle}
                                        placeholder="Enter Lane ID"
                                        value={field.lane_id}
                                        onChange={(e) => this.handleInputChange(e, field.id, 'lane_id')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <FormGroup>
                                    <Label>Lane Number</Label>
                                    <Input
                                        type="text"
                                        style={inputFontStyle}
                                        placeholder="Enter Lane Number"
                                        value={field.lane_number}
                                        onChange={(e) => this.handleInputChange(e, field.id, 'lane_number')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="2">
                                <FormGroup>
                                    <Label>Lane Status</Label>
                                    <label className="switch">
                                        <Input
                                            type="checkbox"
                                            id="lane_status"
                                            checked={this.state.lane_status.lane_status}
                                            onChange={(e) => this.handleInputChange(e, field.id, 'lane_status')}
                                        />
                                        <span className="slider"></span>
                                    </label>
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <Label>RFID Module IP Address</Label>
                                    <Input
                                        type="text"
                                        style={inputFontStyle}
                                        placeholder="Enter IP Address"
                                        value={field.rfid_module_ip_address}
                                        onChange={(e) => this.handleInputChange(e, field.id, 'rfid_module_ip_address')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <Label>RFID Module IP Number</Label>
                                    <Input
                                        type="text"
                                        style={inputFontStyle}
                                        placeholder="Enter IP Number"
                                        value={field.rfid_module_ip_number}
                                        onChange={(e) => this.handleInputChange(e, field.id, 'rfid_module_ip_number')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <Label>RFID Module IP Password</Label>
                                    <Input
                                        type="password"
                                        style={inputFontStyle}
                                        placeholder="Enter IP Password"
                                        value={field.rfid_module_ip_password}
                                        onChange={(e) => this.handleInputChange(e, field.id, 'rfid_module_ip_password')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="12">
                                <FormGroup>
                                    <Label>RFID Module URL</Label>
                                    <Input
                                        type="text"
                                        style={inputFontStyle}
                                        placeholder="Enter URL"
                                        value={field.rfid_module_url}
                                        onChange={(e) => this.handleInputChange(e, field.id, 'rfid_module_url')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <Label>Camera Module IP Address</Label>
                                    <Input
                                        type="text"
                                        style={inputFontStyle}
                                        placeholder="Enter IP Address"
                                        value={field.camera_address}
                                        onChange={(e) => this.handleInputChange(e, field.id, 'camera_address')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <Label>Camera Module IP Number</Label>
                                    <Input
                                        type="text"
                                        style={inputFontStyle}
                                        placeholder="Enter IP Number"
                                        value={field.camera_id}
                                        onChange={(e) => this.handleInputChange(e, field.id, 'camera_id')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <Label>Camera Module Password</Label>
                                    <Input
                                        type="password"
                                        style={inputFontStyle}
                                        placeholder="Enter Password"
                                        value={field.camera_password}
                                        onChange={(e) => this.handleInputChange(e, field.id, 'camera_password')}
                                    />
                                </FormGroup>
                            </Col><Col md="2"></Col><Col md="1"></Col>
                            <Col md="10">
                                <FormGroup>
                                    <Label>Camera Module URL</Label>
                                    <Input
                                        type="text"
                                        style={inputFontStyle}
                                        placeholder="Enter URL"
                                        value={field.camera_url}
                                        onChange={(e) => this.handleInputChange(e, field.id, 'camera_url')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="2" className="mt-4">
                                <button type="button" onClick={() => this.handleAddFields()} style={plusStyle}>+</button>&nbsp;
                                <button type="button" onClick={() => this.handleRemoveFields()} style={minusStyle}>-</button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            );
        }
        return laneTextbox;
    };

    onSubmit = async (event) => {
        event.preventDefault();
        try {
            const { plaza_id, gate_id, gate_name, numFields, fields, description } = this.state;
            const response = {
                plaza_id: plaza_id,
                gate_id: gate_id,
                gate_name: gate_name,
                no_of_lane: numFields,
                lanes: fields,
                description: description
            };
            const insertResponse = await this.props.addLane(response);
            toast.success(insertResponse.message, { autoClose: 10000 });
            window.open("/lane/", "_self");
        } catch (err) {
            toast.error(err.response.data.message, { autoClose: 10000 });
            console.log(err);
        }
    };

    render() {
        const { plaza, gateList, listLaneDetailsByGateId, is_available_lane, numFields } = this.state;
        const inputFontStyle = { fontSize: '10px' };
        const activeStyle = { "width": "80px", "backgroundColor": "#ccffcc", "color": "#008000", "borderRadius": "15px", "fontSize": "10px", "textAlign": "center" }
        const inactiveStyle = { "width": "80px", "backgroundColor": "#ffe6e6", "color": "#ff0000", "borderRadius": "15px", "fontSize": "10px", "textAlign": "center" }
        return (
            <Fragment>
                <div className='page-title'>
                    <Row>
                        <Col xs='6'></Col>
                        <Col xs='6'>
                            <ol className='breadcrumb'>
                                <li className='breadcrumb-item'>
                                    <Link to="/dashboard/">
                                        <SvgIcon iconId='stroke-home' />
                                    </Link>
                                </li>
                                <li className='breadcrumb-item'>
                                    <Link style={{ "color": "#000" }} to="/lane/">Lane</Link>
                                </li>
                                <li className='breadcrumb-item active'>Add Lane</li>
                            </ol>
                        </Col>
                    </Row>
                </div>
                <ToastContainer />
                <Container fluid={true} className='search-page'>
                    <Row>
                        <Col sm='12'>
                            <Form onSubmit={this.onSubmit}>
                                <Card>
                                    <CardHeader className='d-flex align-items-center justify-content-between'>
                                        <H5>Add Lane</H5>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col md="1"></Col>
                                            <Col md="5">
                                                <FormGroup>
                                                    <Label>Select Parking Plaza</Label>
                                                    <Input style={inputFontStyle} type="select" id="plaza" onChange={this.handleChange}>
                                                        <option>Select Parking Plaza</option>
                                                        {plaza.map((data, i) =>
                                                            <option key={i} value={data.id}>{data.p_name}</option>
                                                        )}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col md="5">
                                                <FormGroup>
                                                    <Label>Select Gate</Label>
                                                    <Input style={inputFontStyle} type="select" id="gate" onChange={this.handleChange}>
                                                        <option>Select Gate</option>
                                                        {gateList.map((data, i) =>
                                                            <option key={i} value={data.gate_name}>{data.gate_name}</option>
                                                        )}
                                                    </Input>
                                                </FormGroup>
                                            </Col><Col md="6"></Col>
                                            <Col md="1"></Col>
                                            {is_available_lane !== 0 ? <span style={{ "marginLeft": "105px" }}>
                                                <Label>Available Lane</Label>
                                                <Col md="8">
                                                    <table id="customers">
                                                        <thead>
                                                            <tr>
                                                                <th>Lane Name</th>
                                                                <th>Lane ID</th>
                                                                <th>Lane Number</th>
                                                                <th>Lane Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {listLaneDetailsByGateId.map((data, i) =>
                                                                <tr key={i}>
                                                                    <td>{data.lane_name}</td>
                                                                    <td>{data.lane_id}</td>
                                                                    <td>{data.lane_number}</td>
                                                                    {data.lane_status ? <td><p style={activeStyle}>Active</p></td> : <td><p style={inactiveStyle}>Inactive</p></td>}
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </Col>
                                            </span> : <span></span>}
                                        </Row>
                                        <Row>
                                            <Col md="1"></Col>
                                            <Col md="10">
                                                <FormGroup>
                                                    <Label>No Of Lane</Label>
                                                    <Input
                                                        type="number"
                                                        style={inputFontStyle}
                                                        value={numFields}
                                                        onChange={this.handleNumFieldsChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {this.dynamicInputField()}
                                        <Row>
                                            <Col md="1"></Col>
                                            <Col md="10">
                                                <FormGroup className="mb-3">
                                                    <Label className="form-label">Description</Label>
                                                    <Input
                                                        type="textarea"
                                                        style={inputFontStyle}
                                                        placeholder="Enter Description"
                                                        onChange={(e) => this.setState({ description: e.target.value })}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter className="text-center">
                                        <Btn attrBtn={{ color: "primary", type: "submit" }} >{Save}</Btn>&nbsp;&nbsp;&nbsp;
                                        <Link to="/lane/">
                                            <Btn attrBtn={{ color: "primary", type: "reset" }} >{Cancel}</Btn>
                                        </Link>
                                    </CardFooter>
                                </Card>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}
const mapStateToProps = () => { return {} };

const mapDispatchToProps = {
    addLane: laneOperations.addLane,
    listLaneDetailsByGateId: laneOperations.listLaneDetailsByGateId,
    listParkingPlaza: parkingPlazaOperations.listParkingPlaza,
    listGateDetailsByPlazaId: gateOperations.listGateDetailsByPlazaId
};

export default connect(mapStateToProps, mapDispatchToProps)(AddLane)