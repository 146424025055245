import React, { Component, Fragment } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import "../../../assets/scss/progress.tracker.css";
import '../../../assets/scss/table.css';
import "../../../assets/scss/toggle.css";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import SvgIcon from '../../../Components/Common/Component/SvgIcon.jsx';
import { H5, Btn } from "../../../AbstractElements.jsx";
import { CardBody, CardHeader, Form, FormGroup, Label, Input, Row, Col, Card, Container, CardFooter } from 'reactstrap';
import { Cancel, Save } from "../../../Constant/index.jsx";
import { parkingPlazaOperations } from '../../../state/ducks/parking-plaza/index.js';
import { masterOperations } from "../../../state/ducks/master/index.js";
import { gateOperations } from '../../../state/ducks/gate/index.js';

class AddGate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: [{ id: 1, gate_name: '', gate_id: '', gate_number: '', floor_id: '', gate_status: false }],
            numFields: 0,
            plaza: [],
            floor: [],
            plaza_id: 0,
            floor_id: 0,
            GateDataByPlaza: [],
            checkboxes: false,
            numberOfCheckboxes: 0,
            checkboxValues: []
        };
    }

    async componentDidMount() {
        try {
            this.loadLocalStorageData();
            const { numFields } = this.state;
            this.setState({ gate_status: Array.from({ length: numFields }, () => ({ gate_status: false })) });
        } catch (err) {
            console.log(err);
        }
    }

    loadLocalStorageData = async () => {
        // Store plaza
        localStorage.removeItem('plaza');
        let plaza = localStorage.getItem('plaza');
        if (plaza) {
            plaza = JSON.parse(plaza);
            this.setState({ plaza });
        } else {
            let response = await this.props.listParkingPlaza();
            if (response.payload.length !== 0) {
                const searchString = JSON.stringify(response.payload);
                localStorage.setItem('plaza', searchString);
                plaza = JSON.parse(searchString);
                this.setState({ plaza });
            }
        }

        // Store Floor
        let floor = localStorage.getItem('floor');
        if (floor) {
            floor = JSON.parse(floor);
            this.setState({ floor });
        } else {
            let response = await this.props.floorList();
            if (response.payload.length !== 0) {
                const searchString = JSON.stringify(response.payload);
                localStorage.setItem('floor', searchString);
                floor = JSON.parse(searchString);
                this.setState({ floor });
            }
        }
    }

    handleChange = async (e) => {
        try {
            let tid = e.target.id;
            switch (tid) {
                case 'plaza':
                    this.setState({ plaza_id: e.target.value });
                    const response = await this.props.listGateDetailsByPlazaId({ plazaId: e.target.value });
                    this.setState({ GateDataByPlaza: response.payload });
                    break;
                default:
                    console.log("No Matching target found");
                    break;
            }
        } catch (err) {
            console.log(err);
        }
    };

    handleInputChange = (event, id, fieldName) => {
        const { value, type, checked } = event.target;
        const fieldValue = type === 'checkbox' ? checked : value;
        this.setState((prevState) => ({
            fields: prevState.fields.map((field) =>
                field.id === id ? { ...field, [fieldName]: fieldValue } : field
            ),
        }));
    };

    handleAddFields = () => {
        this.setState((prevState) => ({ numFields: prevState.numFields + 1 }));
        this.setState((prevState) => {
            const { fields } = prevState;
            const newField = {
                id: fields.length + 1,
                gate_name: '',
                gate_id: '',
                gate_number: '',
                floor_id: '',
                gate_status: false,
            };
            return {
                fields: [...fields, newField],
            };
        });
    };

    handleRemoveFields = () => {
        this.setState((prevState) => ({ numFields: prevState.numFields - 1 }));
        this.setState((prevState) => {
            const { fields } = prevState;
            const newField = {
                id: fields.length - 1,
                gate_name: '',
                gate_id: '',
                gate_number: '',
                floor_id: '',
                gate_status: false,
            };
            return {
                fields: [...fields, newField],
            };
        });
    };

    handleNumFieldsChange = (event) => {
        const numFields = parseInt(event.target.value, 10) || 0;
        this.setState((prevState) => {
            const currentFields = prevState.fields.slice(0, numFields);
            const newFields = Array.from({ length: numFields - currentFields.length }, (_, index) => ({
                id: currentFields.length + index + 1,
                gate_name: '',
                gate_id: '',
                gate_number: '',
                floor_id: '',
                gate_status: false,
            }));
            return {
                numFields,
                fields: [...currentFields, ...newFields],
            };
        });
    };

    dynamicInputField = () => {
        const { numFields, floor, fields } = this.state;
        const inputFontStyle = { fontSize: '10px' };
        const plusStyle = { border: "#48bb78 2px solid", backgroundColor: "#48bb78", color: "#fff", fontWeight: "bold" };
        const minusStyle = { border: "#ff6666 2px solid", backgroundColor: "#ff6666", color: "#fff", fontWeight: "bold" };
        const gateTextbox = [];
        for (let i = 0; i < numFields; i++) {
            const field = fields[i] || {};
            gateTextbox.push(
                <Row key={i} className="mb-1">
                    <Col md="1"></Col>
                    <Col md="11">
                        <Label style={{ "color": "#000" }}>Gate {i + 1} Information</Label>
                    </Col>
                    <Col md="1"></Col>
                    <Col md="9" style={{ "marginLeft": "4%", "background": "#d9d9d9", "borderRadius": "10px" }}>
                        <Row>
                            <Col md="4">
                                <FormGroup>
                                    <Label>Gate Name</Label>
                                    <Input
                                        type="text"
                                        style={inputFontStyle}
                                        placeholder="Enter Gate Name"
                                        value={field.gate_name}
                                        onChange={(e) => this.handleInputChange(e, field.id, 'gate_name')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <Label>Gate ID</Label>
                                    <Input
                                        type="text"
                                        style={inputFontStyle}
                                        placeholder="Enter Gate ID"
                                        value={field.gate_id}
                                        onChange={(e) => this.handleInputChange(e, field.id, 'gate_id')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <Label>Gate Number</Label>
                                    <Input
                                        type="text"
                                        style={inputFontStyle}
                                        placeholder="Enter Gate Number"
                                        onChange={(e) => this.handleInputChange(e, field.id, 'gate_number')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup>
                                    <Label>No of Floor Associated</Label>
                                    <Input style={inputFontStyle} type="select" id="floor_id"
                                        onChange={(e) => this.handleInputChange(e, field.id, 'floor_id')}>
                                        <option>Select Floor</option>
                                        {floor.map((data, i) =>
                                            <option key={i} value={data.name}>{data.name}</option>
                                        )}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col md="2">
                                <FormGroup>
                                    <Label>Gate Status</Label>
                                    <label className="switch">
                                        <Input
                                            type="checkbox"
                                            id="gate_status"
                                            checked={this.state.gate_status.gate_status}
                                            onChange={(e) => this.handleInputChange(e, field.id, 'gate_status')}
                                        />
                                        <span className="slider"></span>
                                    </label>
                                </FormGroup>
                            </Col><Col md="2"></Col>
                            <Col md="3" className="mt-4">
                                <button type="button" onClick={() => this.handleAddFields()} style={plusStyle}>+</button>&nbsp;
                                <button type="button" onClick={() => this.handleRemoveFields()} style={minusStyle}>-</button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            );
        }
        return gateTextbox;
    };

    onSubmit = async (event) => {
        event.preventDefault();
        try {
            const { fields } = this.state;
            const response = {
                plaza_id: this.state.plaza_id,
                no_of_gate: this.state.numFields,
                gates: fields,
                description: this.state.description
            };
            const insertResponse = await this.props.addGate(response);
            toast.success(insertResponse.message, { autoClose: 10000 });
            window.open("/gate/", "_self");
        } catch (err) {
            toast.error(err.response.data.message, { autoClose: 10000 });
            console.log(err);
        }
    };

    render() {
        const { plaza, GateDataByPlaza, plaza_id, numFields } = this.state;
        const inputFontStyle = { fontSize: '10px' };
        const activeStyle = { "width": "80px", "backgroundColor": "#ccffcc", "color": "#008000", "borderRadius": "15px", "fontSize": "10px", "textAlign": "center" }
        const inactiveStyle = { "width": "80px", "backgroundColor": "#ffe6e6", "color": "#ff0000", "borderRadius": "15px", "fontSize": "10px", "textAlign": "center" }
        return (
            <Fragment>
                <div className='page-title'>
                    <Row>
                        <Col xs='6'></Col>
                        <Col xs='6'>
                            <ol className='breadcrumb'>
                                <li className='breadcrumb-item'>
                                    <Link to="/dashboard/">
                                        <SvgIcon iconId='stroke-home' />
                                    </Link>
                                </li>
                                <li className='breadcrumb-item'>
                                    <Link style={{ "color": "#000" }} to="/gate/">Gate</Link>
                                </li>
                                <li className='breadcrumb-item active'>Add Gate</li>
                            </ol>
                        </Col>
                    </Row>
                </div>
                <ToastContainer />
                <Container fluid={true} className='search-page'>
                    <Row>
                        <Col sm='12'>
                            <Form onSubmit={this.onSubmit}>
                                <Card>
                                    <CardHeader className='d-flex align-items-center justify-content-between'>
                                        <H5>Add Gate</H5>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col md="1"></Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label>Select Parking Plaza</Label>
                                                    <Input style={inputFontStyle} type="select" id="plaza" onChange={this.handleChange}>
                                                        <option>Select Parking Plaza</option>
                                                        {plaza.map((data, i) =>
                                                            <option key={i} value={data.id}>{data.p_name}</option>
                                                        )}
                                                    </Input>
                                                </FormGroup>
                                            </Col><Col md="7"></Col><Col md="1"></Col>
                                            {plaza_id !== 0 ?
                                                <Col md="10">
                                                    <Label>Available Gate</Label>
                                                    <table id="customers">
                                                        <thead>
                                                            <tr>
                                                                <th>Gate Name</th>
                                                                <th>Gate ID</th>
                                                                <th>Gate Number</th>
                                                                <th>Gate Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {GateDataByPlaza.map((data, i) =>
                                                                <tr key={i}>
                                                                    <td>{data.gate_name}</td>
                                                                    <td>{data.gate_id}</td>
                                                                    <td>{data.gate_number}</td>
                                                                    {data.gate_status ? <td><p style={activeStyle}>Active</p></td> : <td><p style={inactiveStyle}>Inactive</p></td>}
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </Col> : <Col></Col>}
                                        </Row>
                                        <Row>
                                            <Col md="1"></Col>
                                            <Col md="10">
                                                <FormGroup>
                                                    <Label>No of Gate</Label>
                                                    <Input
                                                        type="number"
                                                        style={inputFontStyle}
                                                        placeholder="Enter No of Gate"
                                                        value={numFields}
                                                        onChange={this.handleNumFieldsChange}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {this.dynamicInputField()}
                                        <Row>
                                            <Col md="1"></Col>
                                            <Col md="10">
                                                <FormGroup className="mb-3">
                                                    <Label className="form-label">Description</Label>
                                                    <Input
                                                        type="textarea"
                                                        style={inputFontStyle}
                                                        placeholder="Enter Description"
                                                        onChange={(e) => this.setState({ description: e.target.value })}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter className="text-center">
                                        <Btn attrBtn={{ color: "primary", type: "submit" }} >{Save}</Btn>&nbsp;&nbsp;&nbsp;
                                        <Link to="/gate/">
                                            <Btn attrBtn={{ color: "primary", type: "reset" }} >{Cancel}</Btn>
                                        </Link>
                                    </CardFooter>
                                </Card>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}
const mapStateToProps = () => { return {} };

const mapDispatchToProps = {
    addGate: gateOperations.addGate,
    listGateDetailsByPlazaId: gateOperations.listGateDetailsByPlazaId,
    listParkingPlaza: parkingPlazaOperations.listParkingPlaza,
    floorList: masterOperations.floorList
};

export default connect(mapStateToProps, mapDispatchToProps)(AddGate)