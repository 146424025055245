import React from "react";
import "./index.scss";
import App from "./App";
import "./i18n";
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import configureStore from "./state/store";
import { createRoot } from 'react-dom/client';

let user = localStorage.getItem('user');
const token = localStorage.getItem('token');
const menus = localStorage.getItem('menus') ? JSON.parse(localStorage.getItem('menus')) : [];

const store = configureStore({
  auth: {
    isAuthenticated: !!user,
    session: { user: user ? JSON.parse(user) : null, token },
    menus: menus
  }
});

createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <App />
  </Provider>
);

serviceWorker.unregister();