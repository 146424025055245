import React, { Fragment } from 'react';
import { CardBody, Form, FormGroup, Input, Label, Row, Col, Container, CardFooter, Card } from 'reactstrap';
import { H3, Btn } from '../../../AbstractElements';
import { Update, Cancel, Username, Email, FullName, Role } from '../../../Constant';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as API_URL from "../../../env";
import request from "../../../utils/request";
import { connect } from 'react-redux';
import SvgIcon from '../../../Components/Common/Component/SvgIcon';
import { userOperations } from '../../../state/ducks/user';
import { roleOperations } from '../../../state/ducks/role';

class MyProfile extends React.Component {
    
    constructor() {
        super();
        this.state = {
            roles: [],
            selectedUser: {}
        }
    }

    async componentDidMount() {
        this.loadLocalStorageData();
        let user = localStorage.getItem('user');
        if (user) {
            user = JSON.parse(user);
            this.setState({ user });
        }
        const userId = user.id;
        const options = {
            url: API_URL.BACKEND_SERVICE_API_URL+`/user/get-user-details/${userId}`,
            method: "GET",
            headers: {
                authorization: localStorage.getItem('token')
            }
        };
        let response = await request(options);
        this.setState({ selectedUser: response.payload });
    }

    loadLocalStorageData = async () => {
        //Store roles
        let roles = localStorage.getItem('roles');
        if (roles) {
            roles = JSON.parse(roles);
            this.setState({ roles });
        } else {
            let response = await this.props.listRoles();
            if(response.payload.length !== 0) {
                const rolest = JSON.stringify(response.payload);            
                localStorage.setItem('roles', rolest);
                roles = JSON.parse(rolest);
                this.setState({ roles });
            }
        }
    }

    onUpdate = async (e) => {
        e.preventDefault()
        try {
            let user = localStorage.getItem('user');
            if (user) {
                user = JSON.parse(user);
                this.setState({ user });
            }
            const { selectedUser } = this.state
            let response = await this.props.updateUser({
                id: selectedUser.id,
                name: selectedUser.name,
                user_name: selectedUser.user_name,
                email: selectedUser.email,
                phone_no: selectedUser.phone_no,
                role_id: selectedUser.role_id
            });
            toast.success(response.message, { autoClose: 10000 });
            window.open("/dashboard/","_self");
        } catch (err) {
            toast.error(err.response.data.message, { autoClose: 10000 });
            console.log(err);
        }
    }

    handleInput = async (e) => {
        try {
            let tid = e.target.id;
            const { selectedUser } = this.state;
            switch(tid){
                case 'name':
                    const name = e.target.value;           
                    this.setState({
                        selectedUser: {
                            ...selectedUser,
                            name
                        }
                    });
                    break;
                case 'user_name':
                    const user_name = e.target.value;           
                    this.setState({
                        selectedUser: {
                            ...selectedUser,
                            user_name
                        }
                    });
                    break;
                case 'email':
                    const email = e.target.value;           
                    this.setState({
                        selectedUser: {
                            ...selectedUser,
                            email
                        }
                    });
                    break;
                case 'phone_no':
                    const phone_no = e.target.value;           
                    this.setState({
                        selectedUser: {
                            ...selectedUser,
                            phone_no
                        }
                    });
                    break;
                case 'roles':
                    const role_id = e.target.value;           
                    this.setState({
                        selectedUser: {
                            ...selectedUser,
                            role_id
                        }
                    });
                    break;
                default:
                    console.log("No Matching target found in handleInput");
                    break;
            }
        } catch (err) {
            console.log(err);
        }
    }

    render() {
        const { selectedUser, roles } = this.state
        const inputFontStyle = { fontSize: '10px' }
        return (
            <Fragment>
                <div className='page-title'>
                    <Row>
                        <Col xs='6'><H3>My Profile</H3></Col>
                        <Col xs='6'>
                            <ol className='breadcrumb'>
                                <li className='breadcrumb-item'>
                                    <Link to="/dashboard/">
                                        <SvgIcon iconId='stroke-home' />
                                    </Link>
                                </li>
                                <li className='breadcrumb-item active'>My Profile</li>
                            </ol>
                        </Col>
                    </Row>
                </div>
                <ToastContainer />
                    <Container fluid={true}>
                        <div className='edit-profile'>
                            <Row>
                                <Col>
                                    <Form onSubmit={this.onUpdate}>
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col md="6">
                                                        <FormGroup className="mb-3"> 
                                                            <Label className="form-label">{FullName}</Label>
                                                            <Input style={inputFontStyle} name="name" id="name" type="text" placeholder="Full Name" value={selectedUser.name || ''} onChange={this.handleInput}/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="6">
                                                        <FormGroup> 
                                                            <Label className="form-label">{Username}</Label>
                                                            <Input style={inputFontStyle} name="user_name" id="user_name" type="text" placeholder="User Name" value={selectedUser.user_name || ''} onChange={this.handleInput} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="6">
                                                        <FormGroup> 
                                                            <Label className="form-label">{Email}</Label>
                                                            <Input style={inputFontStyle} name="email" id="email" type="email" placeholder="Phone" value={selectedUser.email || ''} onChange={this.handleInput} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="6">
                                                        <FormGroup> 
                                                            <Label className="form-label">Phone Number</Label>
                                                            <Input style={inputFontStyle} name="phone_no" id="phone_no" type="text" placeholder="Phone" value={selectedUser.phone_no || ''} onChange={this.handleInput} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md="6">
                                                        <FormGroup> 
                                                            <Label className="form-label">{Role}</Label>
                                                            <Input style={inputFontStyle} type="select" name="roles" id="roles" value={selectedUser.role_id || ''} onChange={this.handleInput}>
                                                                <option value=''>Select Role</option>
                                                                {roles.map((data, i) =>
                                                                    <option key={i} value={data.id}>{data.role_name}</option>
                                                                )}
                                                            </Input>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                            <CardFooter className="text-center">
                                                <Btn attrBtn={{ color: "primary", type: "submit" }} >{Update}</Btn>&nbsp;&nbsp;&nbsp;
                                                <Link to="/dashboard/">
                                                    <Btn attrBtn={{ color: "primary", type: "reset" }} >{Cancel}</Btn>
                                                </Link>
                                            </CardFooter>
                                        </Card>
                                    </Form>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </Fragment>
            )
        }
    }
const mapStateToProps = () => { return {} };

const mapDispatchToProps = {
    updateUser: userOperations.updateUser,
    listRoles: roleOperations.listRoles
};
    
export default connect(mapStateToProps, mapDispatchToProps)(MyProfile)