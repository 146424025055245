import React, { Component, Fragment } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import "../../../assets/scss/progress.tracker.css";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import SvgIcon from '../../../Components/Common/Component/SvgIcon.jsx';
import { H5, Btn } from "../../../AbstractElements.jsx";
import { CardBody, CardHeader, Form, FormGroup, Label, Input, Row, Col, Card, Container, CardFooter } from 'reactstrap';
import { validEmail, validMobileNumber } from '../../validation.jsx';
import { parkingPlazaOperations } from '../../../state/ducks/parking-plaza/index.js';
import { masterOperations } from '../../../state/ducks/master/index.js';
import { filterCitiesByState } from "../../../utils/helper.js";
import { Cancel, Save } from "../../../Constant/index.jsx";

class AddParkingPlaza extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isEmailValid: true,
            isMobileValid: true,
            states: [],
            cities: []
        };
    }

    async componentDidMount() {
        try {
            this.loadLocalStorageData();
        } catch (err) {
            console.log(err);
        }
    }

    loadLocalStorageData = async () => {
        // Store Cities
        let cities = localStorage.getItem('cities');
        if (cities) {
            cities = JSON.parse(cities);
            this.setState({ cities });
        } else {
            let response = await this.props.listCity();
            if(response.payload.length !== 0) {
                const searchString = JSON.stringify(response.payload);            
                localStorage.setItem('cities', searchString);
                cities = JSON.parse(searchString);
                this.setState({ cities });
            }
        }
        // Store States
        let states = localStorage.getItem('states');
        if (states) {
            states = JSON.parse(states);
            this.setState({ states });
        } else {
            let response = await this.props.listState();
            if(response.payload.length !== 0) {
                const searchString = JSON.stringify(response.payload);            
                localStorage.setItem('states', searchString);
                states = JSON.parse(searchString);
                this.setState({ states });
            }
        }
    }

    handleChange = async (e) => {
        try {
            let tid = e.target.id;
            switch(tid){
                case 'email':
                    const email = e.target.value;
                    const isEmailValid = !email || validEmail.test(email);
                    this.setState({ email: email, isEmailValid });
                    break;
                case 'phone_no':
                    const phone_no = e.target.value;
                    const isMobileValid = !phone_no || validMobileNumber.test(phone_no);
                    this.setState({ phone_no: phone_no, isMobileValid });
                    break;
                case 'city':
                    this.setState({ city_id: e.target.value });
                    break;
                case 'state':
                    this.state.state_id = e.target.value;
                    const cities =  filterCitiesByState(this.state.state_id);
                    this.setState({ cities });
                    break;
                default:
                    console.log("No Matching target found");
                    break;
            }
        } catch (err) {
            console.log(err);
        }
    };
    
    onInsert = async (event) => {
        event.preventDefault();
        try {
            const response = {
                p_name: this.state.plazaName,
                p_id: this.state.plazaID,
                npci_id: this.state.npciId,
                p_address: this.state.plazaAddress,
                latitude: this.state.latitude,
                longitude: this.state.longitude,
                state_id: this.state.state_id,
                city_id: this.state.city_id,
                p_cont_per_name: this.state.phoneName,
                p_cont_per_phone_no: this.state.phone_no,
                p_cont_per_email: this.state.email,
                p_desc: this.state.plazaDescription,
            };
            const insertResponse = await this.props.addParkingPlaza(response);
            toast.success(insertResponse.message, { autoClose: 10000 });
            window.open("/parking-plazas/","_self");
        } catch (err) {
            toast.error(err.response.data.message, { autoClose: 10000 });
            console.log(err);
        }
    };

    render() {
        const { states, cities } = this.state;
        const inputFontStyle = { fontSize: '10px' };
        return (
            <Fragment>
                <div className='page-title'>
                    <Row>
                        <Col xs='6'></Col>
                        <Col xs='6'>
                            <ol className='breadcrumb'>
                                <li className='breadcrumb-item'>
                                    <Link to="/dashboard/">
                                        <SvgIcon iconId='stroke-home' />
                                    </Link>
                                </li>
                                <li className='breadcrumb-item'>
                                    <Link style={{"color":"#000"}} to="/parking-plazas/">Parking Plaza</Link>
                                </li>
                                <li className='breadcrumb-item active'>Add Parking Plaza</li>
                            </ol>
                        </Col>
                    </Row>
                </div>
                <ToastContainer />
                <Container fluid={true} className='search-page'>
                    <Row>
                        <Col sm='12'>
                            <Form onSubmit={this.onInsert}>
                                <Card>
                                    <CardHeader className='d-flex align-items-center justify-content-between'>
                                        <H5>Add Parking Plaza</H5>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            <Col md="1"></Col>
                                            <Col md="8">
                                                <FormGroup>
                                                    <Label>Parking Plaza Name</Label>
                                                    <Input
                                                        type="text"
                                                        style={inputFontStyle}
                                                        placeholder="Enter Parking Plaza Name"
                                                        onChange={(e) => this.setState({ plazaName: e.target.value })}
                                                    />
                                                </FormGroup>
                                            </Col><Col md="3"></Col><Col md="1"></Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label>Parking Plaza ID</Label>
                                                    <Input 
                                                        type="text" 
                                                        style={inputFontStyle}
                                                        placeholder="Enter Parking Plaza ID" 
                                                        onChange={(e) => this.setState({ plazaID: e.target.value })}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label>NPCI ID</Label>
                                                    <Input 
                                                        type="text" 
                                                        style={inputFontStyle}
                                                        placeholder="Enter NPCI ID" 
                                                        onChange={(e) => this.setState({ npciId: e.target.value })}
                                                    />
                                                </FormGroup>
                                            </Col><Col md="3"></Col><Col md="1"></Col>
                                            <Col md="8">
                                                <FormGroup>
                                                    <Label>Address</Label>
                                                    <Input 
                                                        type="textarea" 
                                                        style={inputFontStyle} 
                                                        placeholder="Enter Parking Plaza Address" 
                                                        onChange={(e) => this.setState({ plazaAddress: e.target.value })}
                                                    />
                                                </FormGroup>
                                            </Col><Col md="3"></Col><Col md="1"></Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label>Latitude</Label>
                                                    <Input 
                                                        type="text" 
                                                        style={inputFontStyle} 
                                                        placeholder="Enter Latitude" 
                                                        onChange={(e) => this.setState({ latitude: e.target.value })}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup>
                                                    <Label>Longitude</Label>
                                                    <Input 
                                                        type="text" 
                                                        style={inputFontStyle} 
                                                        placeholder="Enter Longitude" 
                                                        onChange={(e) => this.setState({ longitude: e.target.value })}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md="3"></Col><Col md="1"></Col>
                                            <Col md="4">
                                                <FormGroup> 
                                                    <Label>State</Label>
                                                    <Input style={inputFontStyle} type="select" id="state" onChange={this.handleChange}>
                                                        <option>Select State</option>
                                                            {states.map((state, i) =>
                                                                <option key={i} value={state.id}>{state.name}</option>
                                                            )}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup> 
                                                    <Label>City</Label>
                                                    <Input style={inputFontStyle} type="select" id="city" onChange={this.handleChange}>
                                                        <option>Select City</option>
                                                        {cities.map((city, i) =>
                                                            <option key={i} value={city.id}>{city.name}</option>
                                                        )}
                                                    </Input>
                                                </FormGroup>
                                            </Col><Col md="3"></Col><Col md="1"></Col>
                                            <Col md="8">
                                                <FormGroup className="mb-3"> 
                                                    <Label className="form-label">Parking Plaza Contact Person Name</Label>
                                                    <Input 
                                                        type="text" 
                                                        style={inputFontStyle}
                                                        placeholder="Enter Contact Person Name" 
                                                        onChange={(e) => this.setState({ phoneName: e.target.value })}
                                                    />
                                                </FormGroup>
                                            </Col><Col md="3"></Col><Col md="1"></Col>
                                            <Col md="4">
                                                <FormGroup> 
                                                    <Label>Phone Number</Label>
                                                    <Input 
                                                        type="text" 
                                                        id="phone_no"
                                                        style={inputFontStyle} 
                                                        placeholder="Enter Phone Number" 
                                                        value={this.state.phone_no || ''} 
                                                        onChange={this.handleChange}
                                                    />
                                                    {this.state.isMobileValid ? <span></span> : <span style={{ color: "#F61C04" }}>Phone Number must be at least 10 digits.</span>}
                                                </FormGroup>
                                            </Col>
                                            <Col md="4">
                                                <FormGroup> 
                                                    <Label>Email</Label>
                                                    <Input 
                                                        type="email" 
                                                        id="email"
                                                        style={inputFontStyle} 
                                                        placeholder="Enter Email" 
                                                        value={this.state.email || ''} 
                                                        onChange={this.handleChange}
                                                    />
                                                    {this.state.isEmailValid ? <span></span> : <span style={{ color: "#F61C04" }}>Email Id is not valid.</span>}
                                                </FormGroup>
                                            </Col><Col md="3"></Col><Col md="1"></Col>
                                            <Col md="8">
                                                <FormGroup>
                                                    <Label>Description</Label>
                                                    <Input 
                                                        type="textarea" 
                                                        style={inputFontStyle} 
                                                        placeholder="Enter Parking Plaza Description" 
                                                        onChange={(e) => this.setState({ plazaDescription: e.target.value })}
                                                    />
                                                </FormGroup>
                                            </Col>    
                                        </Row>
                                    </CardBody>
                                    <CardFooter className="text-center">
                                        <Btn attrBtn={{ color: "primary", type: "submit" }} >{Save}</Btn>&nbsp;&nbsp;&nbsp;
                                        <Link to="/parking-plazas/">
                                            <Btn attrBtn={{ color: "primary", type: "reset" }} >{Cancel}</Btn>
                                        </Link>
                                    </CardFooter>
                                </Card>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}
const mapStateToProps = () => { return {} };

const mapDispatchToProps = {
    addParkingPlaza: parkingPlazaOperations.addParkingPlaza,
    listState: masterOperations.listState,
    listCity: masterOperations.listCity
};

export default connect(mapStateToProps, mapDispatchToProps)(AddParkingPlaza)