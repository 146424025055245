import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Col, Form, FormGroup, Input, Label } from 'reactstrap';
import { Btn, H5, P, Image } from '../../../AbstractElements.jsx';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import logoWhite from '../../../assets/images/logo/logo.png';
import { NewPassword, ConfirmPassword, Cancel } from '../../../Constant/index.jsx';
import { authOperations } from '../../../state/ducks/auth/index.js';
import { validPassword } from '../../validation.jsx';

class ResetPassword extends React.Component {

  constructor() {
    super();
    this.state = {
      pass_word: '',
      togglePassword: false,
      isPasswordValid: true,
    }
  }

  togglePasswordVisibility = () => {
    this.setState((prevState) => ({ togglePassword: !prevState.togglePassword }));
  };

  onResetPassword = async (event) => {
    event.preventDefault();
    try {
      const email = localStorage.getItem("selectedEmail");
      let response = await this.props.resetPassword({
        email: email,
        newPassword: this.state.newPassword,
        confirmPassword: this.state.confirmPassword
      });
      toast.success(response.message, { autoClose: 10000 });
      this.setState({ newPassword: '', confirmPassword: '' });
      window.open("/login/","_self");
    } catch (err) {
      toast.error(err.response.data.message, { autoClose: 10000 });
      console.log(err);
    }
  }

  handleChange = async (e) => {
    try {
      let tid = e.target.id;
      switch(tid){
        case 'newPassword':
          const newPassword = e.target.value;
          const isPasswordValid = !newPassword || validPassword.test(newPassword);
          this.setState({ newPassword: newPassword, isPasswordValid });
          break;
        default:
          console.log("No Matching target found");
          break;
      }
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const { togglePassword } = this.state;
    return (
      <Fragment>
        <ToastContainer />
        <div className='login-card'>
          <div>
            <div className='login-main'>
              <div>
                <Link className="logo" to="/signup/">
                  <Image attrImage={{ className: 'img-fluid for-light', src: logoWhite, alt: 'looginpage' }} />
                </Link>
              </div>
              <Form className='theme-form login-form' onSubmit={this.onResetPassword}>
                <H5>Reset Password</H5>
                <P>Enter your new password to Reset Password</P>
                <FormGroup className='position-relative'>
                  <Label className='col-form-label m-0 pt-0'>{NewPassword}</Label>
                  <div className='position-relative'>
                    <Input className='form-control' type={togglePassword ? 'text' : 'password'} placeholder='New Password' name="newPassword" id="newPassword" required value={this.state.newPassword || ''} onChange={this.handleChange}  />
                    {this.state.isPasswordValid ? <span></span> : <span style={{ color: "#F61C04" }}>Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, and one digit.</span>}
                    <div className='show-hide' onClick={this.togglePasswordVisibility}>
                      <span className={togglePassword ? '' : 'show'}></span>
                    </div>
                  </div>
                </FormGroup>
                <Col>
                  <Label className='col-form-label m-0 pt-0'>{ConfirmPassword}</Label>
                  <Input className='form-control' type='password' required placeholder='Confirm Password' onChange={(e) => this.setState({ confirmPassword: e.target.value })} />
                </Col>
                <FormGroup className="text-center mt-2">
                  <Btn attrBtn={{ color: 'primary', type: 'submit' }}>Reset Password</Btn>&nbsp;&nbsp;&nbsp;
                  <Link to="/login/">
                    <Btn attrBtn={{ color: "primary", type: "reset" }} >{Cancel}</Btn>
                  </Link>
                </FormGroup>
              </Form>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };
}
const mapStateToProps = () => { return {} };

const mapDispatchToProps = { resetPassword: authOperations.resetPassword };

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)