import React, { Fragment } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { H3, Btn } from "../../../AbstractElements";
import { Row, Col, CardBody, CardFooter, Form, FormGroup, Label, Input, Container, Card } from 'reactstrap';
import { Save, Cancel, Email, Role, Username, Password, FullName } from '../../../Constant';
import { validEmail, validPassword } from '../../validation.jsx';
import SvgIcon from '../../../Components/Common/Component/SvgIcon';
import { userOperations } from '../../../state/ducks/user';
import { roleOperations } from '../../../state/ducks/role';

class AddUser extends React.Component {
    
    constructor() {
        super();
        this.state = {
            isEmailValid: true,
            isPasswordValid: true,
            roles: [],
            selectedFile: null,
            previewUrl: null
        }
    }

    async componentDidMount() {
        try {
            this.loadLocalStorageData();
        } catch (err) {
            console.log(err);
        }
    }

    loadLocalStorageData = async () => {
        //Store roles
        let roles = localStorage.getItem('roles');
        if (roles) {
            roles = JSON.parse(roles);
            this.setState({ roles });
        } else {
            let response = await this.props.listRoles();
            if(response.payload.length !== 0) {
                const searchString = JSON.stringify(response.payload);            
                localStorage.setItem('roles', searchString);
                roles = JSON.parse(searchString);
                this.setState({ roles });
            }
        }
    }

    onInsert = async (event) => {
        event.preventDefault();
        try {
            const payload = new FormData();
            payload.append("user", this.state.selectedFile)
            payload.append("name", this.state.fullName)
            payload.append("user_name", this.state.userName)
            payload.append("email", this.state.email)
            payload.append("phone_no", this.state.phone_no)
            payload.append("role_id", this.state.roleId)
            payload.append("pass_word", this.state.pass_word)
            const insertResponse = await this.props.addUser(payload);
            toast.success(insertResponse.message, { autoClose: 10000 });
            window.open("/access-management/","_self");
        } catch (err) {
            toast.error(err.response.data.message, { autoClose: 10000 });
            console.log(err);
        }
    }

    handleChange = async (e) => {
        try {
            let tid = e.target.id;
            switch(tid){
                    case 'email':
                        const email = e.target.value;
                        const isEmailValid = !email || validEmail.test(email);
                        this.setState({ email: email, isEmailValid });
                        break;
                    case 'pass_word':
                        const pass_word = e.target.value;
                        const isPasswordValid = !pass_word || validPassword.test(pass_word);
                        this.setState({ pass_word: pass_word, isPasswordValid });
                        break;
                    case 'roles':
                        this.setState({ roleId: e.target.value });
                        break;
                default:
                    console.log("No Matching target found");
                    break;
            }
        } catch (err) {
            console.log(err);
        }
    };

    handleFileChange = (event) => {
        const file = event.target.files[0];
        this.setState({
          selectedFile: file,
          previewUrl: URL.createObjectURL(file),
        });
    };
    
    handleRemove = () => {
        this.setState({
          selectedFile: null,
          previewUrl: null,
        });
    }; 

    render() {
        const { roles  } = this.state;
        const inputFontStyle = { fontSize: '10px' }
        return (
            <Fragment>
                <ToastContainer />
                <div className='page-title'>
                    <Row>
                        <Col xs='6'>
                            <H3>Add New User</H3>
                        </Col>
                        <Col xs='6'>
                            <ol className='breadcrumb'>
                                <li className='breadcrumb-item'>
                                    <Link to="/dashboard/">
                                        <SvgIcon iconId='stroke-home' />
                                    </Link>
                                </li>
                                <li className='breadcrumb-item'>
                                    <Link style={{"color":"#000"}} to="/access-management/">Access Management</Link>
                                </li>
                                <li className='breadcrumb-item active'>Add New User</li>
                            </ol>
                        </Col>
                    </Row>
                </div>
                <Container fluid={true}>
                    <div className='edit-profile'>
                        <Row>
                            <Col>
                                <Form onSubmit={this.onInsert}>
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                {/* <Col md="4">
                                                    <FormGroup>
                                                        <Label className="form-label">Profile Photo</Label>
                                                        <Input type="file" onChange={this.handleFileChange} />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="4">
                                                    {previewUrl && (
                                                        <div>
                                                            <img style={{ "border": "1px solid #000", "borderRadius": "5px" }} height={110} width={110} src={previewUrl} alt="Preview" />
                                                            <button style={{ "border": "none", "color": "red", "fontSize": "10px" }} onClick={this.handleRemove}>
                                                                Remove
                                                            </button>
                                                        </div>
                                                    )}
                                                </Col> */}
                                                <Col md="8">
                                                    <FormGroup className="mb-3"> 
                                                        <Label className="form-label">{FullName}<span style={{"color": "#bb131a"}}>*</span></Label>
                                                        <Input style={inputFontStyle} type="text" placeholder="Enter your full name" required onChange={(e) => this.setState({ fullName: e.target.value })} />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="8">
                                                    <FormGroup className="mb-3"> 
                                                        <Label className="form-label">{Username}<span style={{"color": "#bb131a"}}>*</span></Label>
                                                        <Input style={inputFontStyle} type="text" placeholder="Enter your user name" required onChange={(e) => this.setState({ userName: e.target.value })} />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="8">
                                                    <FormGroup>
                                                        <Label className="form-label">{Email}</Label>
                                                        <Input style={inputFontStyle} type="email" name="email" id="email" placeholder="Enter your email" value={this.state.email || ''} onChange={this.handleChange} />
                                                        {this.state.isEmailValid ? <span></span> : <span style={{ color: "#F61C04" }}>Email Id is not valid.</span>}
                                                    </FormGroup>
                                                </Col>
                                                <Col md="8">
                                                    <FormGroup className="mb-3"> 
                                                        <Label className="form-label">{Password}<span style={{"color": "#bb131a"}}>*</span></Label>
                                                        <Input style={inputFontStyle} type="password" placeholder="Enter your password" name="pass_word" id="pass_word" required value={this.state.pass_word || ''} onChange={this.handleChange} />
                                                        {this.state.isPasswordValid ? <span></span> : <span style={{ color: "#F61C04" }}>Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, and one digit.</span>}
                                                    </FormGroup>
                                                </Col>
                                                <Col md="8">
                                                    <FormGroup> 
                                                        <Label className="form-label">Phone Number</Label>
                                                        <Input style={inputFontStyle} type="text" name="phone_no" id="phone_no" placeholder="Enter your phone number" onChange={(e) => this.setState({ phone_no: e.target.value })} />
                                                    </FormGroup>
                                                </Col>
                                                <Col md="8">
                                                    <FormGroup> 
                                                        <Label className="form-label">{Role}</Label>
                                                        <Input style={inputFontStyle} type="select" name="roles" id="roles" onChange={this.handleChange}>
                                                            <option value=''>Select Role</option>
                                                            {roles.map((role, i) =>
                                                                <option key={i} value={role.id}>{role.role_name}</option>
                                                            )}
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                        <CardFooter className="text-center">
                                            <Btn attrBtn={{ color: "primary", type: "submit" }} >{Save}</Btn>&nbsp;&nbsp;&nbsp;
                                            <Link to="/access-management/">
                                                <Btn attrBtn={{ color: "primary", type: "reset" }} >{Cancel}</Btn>
                                            </Link>
                                        </CardFooter>
                                    </Card>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </Fragment>
        )
    }
}
const mapStateToProps = () => { return {} };

const mapDispatchToProps = {
    addUser: userOperations.addUser,
    listRoles: roleOperations.listRoles
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUser)