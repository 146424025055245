export const INSERT = "gate/INSERT";
export const INSERT_COMPLETED = "gate/INSERT_COMPLETED";
export const INSERT_FAILED = "gate/INSERT_FAILED";

export const FETCH_LIST = "gate/FETCH_LIST";
export const FETCH_LIST_COMPLETED = "gate/FETCH_LIST_COMPLETED";
export const FETCH_LIST_FAILED = "gate/FETCH_LIST_FAILED";

export const UPDATE = "gate/UPDATE";
export const UPDATE_COMPLETED = "gate/UPDATE_COMPLETED";
export const UPDATE_FAILED = "gate/UPDATE_FAILED";