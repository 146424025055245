import { combineReducers } from "redux";
import * as types from "./types";
import { createReducer } from "../../utils";

const listReducer = createReducer([])({
    [types.INSERT_COMPLETED]: (state, action) => {
        const event = action.payload.payload;
        state.push(event)
        return state.slice()
    },
    [types.FETCH_LIST_COMPLETED]: (state, action) => {
        return action.payload.payload
    },
    [types.UPDATE_COMPLETED]: (state, action) => {
        const payload = action.payload.payload
        return state.map(e => e.id === payload.id ? payload : e)
    }
});

export default combineReducers({
    list: listReducer
});