import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Form, FormGroup, Input, Label, Col } from 'reactstrap';
import { Btn, H5, P, Image } from '../../../AbstractElements.jsx';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import logoWhite from '../../../assets/images/logo/logo.png';
import { Cancel } from '../../../Constant/index.jsx';
import { authOperations } from '../../../state/ducks/auth/index.js';

class ConfirmOTP extends React.Component {

  onConfirmOTP = async (event) => {
    event.preventDefault();
    try {
      const email = localStorage.getItem("selectedEmail");
      let response = await this.props.confirmOTP({
        email: email,
        otp: this.state.otp
      });
      this.setState({ otp: '' });
      toast.success(response.message, { autoClose: 10000 });
      if (response.status === 200) {
        window.open("/reset-password/","_self");
      }
    } catch (err) {
      toast.error(err.response.data.message, { autoClose: 10000 });
      console.log(err);
    }
  }

  render() {
    return (
      <Fragment>
        <ToastContainer />
        <div className='login-card'>
          <div>
            <div className='login-main'>
              <div>
                <Link className="logo" to="/signup/">
                  <Image attrImage={{ className: 'img-fluid for-light', src: logoWhite, alt: 'looginpage' }} />
                </Link>
              </div>
              <Form className='theme-form login-form' onSubmit={this.onConfirmOTP}>
                <H5>Confirm OTP</H5>
                <P>Enter your otp to Confirm OTP</P>
                <Col>
                  <Label className='col-form-label m-0 pt-0'>OTP</Label>
                  <Input type="text" name="otp" id="otp" required placeholder="OTP" onChange={(e) => this.setState({ otp: e.target.value })} />
                </Col>
                <FormGroup className="text-center mt-2">
                  <Btn attrBtn={{ color: 'primary', type: 'submit' }}>Confirm OTP</Btn>&nbsp;&nbsp;&nbsp;
                  <Link to="/login/">
                    <Btn attrBtn={{ color: "primary", type: "reset" }} >{Cancel}</Btn>
                  </Link>
                </FormGroup>
              </Form>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };
}
const mapStateToProps = () => { return {} };

const mapDispatchToProps = { confirmOTP: authOperations.confirmOTP };

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmOTP)