import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Search } from 'react-feather';
import { Button, Table } from 'react-bootstrap';
import { Container, Row, Col, Card, CardBody, CardHeader, Input, CardFooter, Form, Label } from 'reactstrap';
import SvgIcon from '../../../Components/Common/Component/SvgIcon';
import { H3, H6 } from '../../../AbstractElements';
import { reportOperations } from '../../../state/ducks/report';
import Pagination from '../../Pagination';
import '../../../assets/scss/pagination.css';
import { formatDateAndTime } from '../../../utils/helper';

class RealTimeReport extends React.Component {
    constructor() {
        super();
        this.state = {
            realTimeData : [],
            defaultSearchData: [],
            searchData: '',
            currentPage: 1,
            itemsPerPage: 10,
            sortedColumn: null,
            sortOrder: 'asc'
        }
    }
      
    async componentDidMount() {
        try {
            this.loadBase();
            this.timerID = setInterval(() => {
                if (window.location.pathname === "/reports/real-time-report/") {
                    this.loadBase();
                }
            }, 5000);
        } catch (err) {
            console.log(err);
        }
    }

    loadBase = async () => {
        try {
            const response = await this.props.realTimeReport();
            this.setState({ realTimeData: response.payload, defaultSearchData: response.payload });
        } catch (err) {
            console.log(err);
        }
    }

    onSearch = async () => { 
        try {
            let response = await this.props.realTimeReport({ from_date: this.state.fromDate, to_date: this.state.toDate });
            this.setState({ realTimeData: response.payload, defaultSearchData: response.payload });
        } catch (err) {
            console.log("Error While Bill Listing"+err);
        }
    }

    onClear = async () => {        
        try {
            this.componentDidMount();
        } catch (err) {
            console.log("Error While Bill Listing"+err);
        }
    }

    handlePageChange = (pageNumber) => {
        this.setState({ currentPage: pageNumber });
    };

    DefaultSearchChange = (e) => {
        const searchData = e.target.value;
        const defaultSearchData = this.state.realTimeData.filter(data => {
        const valuesToSearch = Object.values(data).filter(value => value !== null);
            return valuesToSearch.some(value =>
                String(value).toLowerCase().includes(searchData.toLowerCase())
            );
        });
        this.setState({ searchData, defaultSearchData });
    };

    handleTableSort = (column) => {
        const { defaultSearchData, sortedColumn, sortOrder } = this.state;
        // Toggle the sortOrder if the same column is clicked again
        const newSortOrder = column === sortedColumn && sortOrder === 'asc' ? 'desc' : 'asc';
    
        // Sort the data based on the selected column and sortOrder
        const sortedData = defaultSearchData.slice().sort((a, b) => {
          if (newSortOrder === 'asc') {
            return a[column] > b[column] ? 1 : -1;
          } else {
            return a[column] < b[column] ? 1 : -1;
          }
        });
    
        this.setState({
            defaultSearchData: sortedData,
            sortedColumn: column,
            sortOrder: newSortOrder,
        });
    };

    render() {
        const { defaultSearchData, currentPage, itemsPerPage, searchData, sortedColumn, sortOrder } = this.state;
        // For Pagination
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentData = defaultSearchData.slice(indexOfFirstItem, indexOfLastItem);
        const totalPages = Math.ceil(defaultSearchData.length / itemsPerPage);
        const inputFontStyle = { fontSize: '10px' };
        const editBtnStyle =  {
            "border": "none", 
            "borderRadius": "3px",
            "background": "#e6e6ff",
            "color": "#000099", 
            "fontSize": "10px"
        }
        return (
            <Fragment>
                <div className='page-title'>
                    <Row>
                        <Col xs='6'>
                            <H3></H3>
                        </Col>
                        <Col xs='6'>
                            <ol className='breadcrumb'>
                                <li className='breadcrumb-item'>
                                    <Link to="/dashboard/">
                                        <SvgIcon iconId='stroke-home' />
                                    </Link>
                                </li>
                                <li className='breadcrumb-item'>
                                    <Link style={{"color":"#000"}} to="/reports/">Report</Link>
                                </li>
                                <li className='breadcrumb-item active'>Real Time Report</li>
                            </ol>
                        </Col>
                    </Row>
                </div>
                <Container fluid={true}>
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader className='d-flex align-items-center justify-content-between'>
                                    <H6>Real Time Report</H6>
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <Row style={{ "marginBottom": "-5px" }}>
                                            <Col md='2' style={{ "marginRight": "-20px" }}>
                                                <Label className="col-form-label">From Date</Label>
                                                <Input style={inputFontStyle} className="form-control digits" type="date" onChange={(e) => this.setState({ fromDate: e.target.value })} />  
                                            </Col>
                                            <Col md='2' style={{ "marginRight": "-20px" }}>
                                                <Label className="col-form-label">To Date</Label>
                                                <Input style={inputFontStyle} className="form-control digits" type="date" onChange={(e) => this.setState({ toDate: e.target.value })} />
                                            </Col>
                                            <Col md='3'>
                                                <Label className="col-form-label" style={{ "visibility": "hidden", "marginTop": "30px", "marginRight": "-30px" }}>Search</Label>
                                                <Button onClick={this.onSearch}>Search</Button>&nbsp;&nbsp;
                                                <Button onClick={this.onClear} type='reset'>Clear</Button>
                                            </Col><Col></Col>
                                            <Col md='3' style={{ "marginBottom": "10px", "marginRight": "15px" }}>
                                                <Label className="col-form-label mt-3"></Label>
                                                <div className="job-filter">
                                                    <div className="faq-form">
                                                        <Input style={inputFontStyle} className="form-control" type="text" placeholder="Search" value={searchData} onChange={this.DefaultSearchChange} />
                                                        <Search className="search-icon" />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                    <Table ref="tbl" striped hover responsive bordered id="data-table-zero">
                                        <thead>
                                            <tr>
                                                <th onClick={() => this.handleTableSort('vehicle_reg_num')}>Vehicle Number {sortedColumn === 'vehicle_reg_num' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                                                <th onClick={() => this.handleTableSort('in_time')}>Entry Date & Time {sortedColumn === 'in_time' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                                                <th onClick={() => this.handleTableSort('out_time')}>Exit Date & Time {sortedColumn === 'out_time' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                                                <th onClick={() => this.handleTableSort('gate_number')}>Gate No. {sortedColumn === 'gate_number' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                                                <th onClick={() => this.handleTableSort('in_lane_number')}>Lane No. {sortedColumn === 'in_lane_number' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                                                <th onClick={() => this.handleTableSort('floor_number')}>Floor No. {sortedColumn === 'floor_number' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                                                <th onClick={() => this.handleTableSort('tariff_amount')}>Tariff Amount {sortedColumn === 'tariff_amount' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                                                <th>Payment Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentData.map((data, i) =>
                                                <tr key={i}>
                                                    <td>{data.vehicle_no}</td>
                                                    <td>{formatDateAndTime(data.in_time)}</td>
                                                    <td>{formatDateAndTime(data.out_time)}</td>
                                                    <td>{data.gate_number}</td>
                                                    <td>{data.exit_lane_number}</td>
                                                    <td>{data.floor_number}</td>
                                                    <td>{data.tariff_amount}</td>
                                                    <td>{data.payment_status}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </CardBody>
                                <CardFooter>
                                    <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={this.handlePageChange} />
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}
const mapStateToProps = () => { return {} };

const mapDispatchToProps = {
    realTimeReport: reportOperations.realTimeReport
};

export default connect(mapStateToProps, mapDispatchToProps)(RealTimeReport)