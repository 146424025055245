import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Search } from 'react-feather';
import { Button, Table } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Container, Row, Col, Card, CardBody, CardHeader, Input, CardFooter, Form } from 'reactstrap';
import SvgIcon from '../../../Components/Common/Component/SvgIcon';
import { H3, Btn, H6 } from '../../../AbstractElements';
import { userOperations } from '../../../state/ducks/user';
import { roleOperations } from '../../../state/ducks/role';
import pauseIcon from '../.././../assets/images/datatable/pause.png';
import editIcon from '../.././../assets/images/datatable/edit.png';
import undoIcon from '../.././../assets/images/datatable/undo.png'
import Pagination from '../../Pagination';
import '../../../assets/scss/pagination.css';
import { formatDateAndTime, getRoleName } from '../../../utils/helper';

class AccessManagement extends React.Component {
    constructor() {
        super();
        this.state = {
            role_name: '',
            userData: [],
            roles: [],
            defaultSearchData: [],
            searchData: '',
            currentPage: 1,
            itemsPerPage: 10,
            sortedColumn: null,
            sortOrder: 'asc'
        }
    }

    async componentDidMount() {
        try {
            this.loadLocalStorageData();
            // get user for role name
            let user = localStorage.getItem('user');
            if (user) {
                user = JSON.parse(user);
                this.setState({ user });
            }
            if (typeof user === 'undefined'){
            } else {
                const role_name = getRoleName(user.role_id);
                this.setState({ role_name: role_name });                    
            }

            const response = await this.props.listUsers();
            this.setState({ userData: response.payload, defaultSearchData: response.payload });
        } catch (err) {
            console.log(err);
        }
    }

    loadLocalStorageData = async () => {
        try {
            let roles = localStorage.getItem('roles');
            if (roles) {
                roles = JSON.parse(roles);
                this.setState({ roles });
            } else {
                const response = await this.props.listRoles();
                if (response.payload.length !== 0) {
                    const rolesString = JSON.stringify(response.payload);
                    localStorage.setItem('roles', rolesString);
                    roles = JSON.parse(rolesString);
                    this.setState({ roles });
                }
            }
        } catch (error) {
            console.error("Error loading data:", error.message);
        }
    }

    onPauseClick = async (e) => {
        const userId = e.target.getAttribute('data-test-id');
        try {
            const deleteResponse = await this.props.deleteUser({ id: userId });
            const response = await this.props.listUsers({ is_active: 1 });
            this.setState({ userData: response.payload, defaultSearchData: response.payload });
            if (response.payload[0].is_active === 0) {
                toast.error(deleteResponse.message, { autoClose: 3000 });
            } else {
                toast.success(deleteResponse.message, { autoClose: 3000 });
            }
        } catch (err) {
            toast.error(err.response.data.message, { autoClose: 10000 });
            console.log(err);
        }
    }

    handleEditClick = (e) => {
        localStorage.setItem("selectedUserId", e.target.getAttribute('data-test-id'));
        window.open("/access-management/update-user/", "_self");
    }

    onSearch = async () => {
        try {
            const search = { is_active: this.state.active, role_id: this.state.roleId };
            const searchString = JSON.stringify(search);
            const options = { "search": searchString };
            let response = await this.props.listUsers(options);
            this.setState({ userData: response.payload, defaultSearchData: response.payload });
        } catch (err) {
            console.log("Error While Bill Listing" + err);
        }
    }

    onNewest = async () => {
        try {
            const search = { is_existing: 1 };
            const searchString = JSON.stringify(search);
            const options = { "search": searchString };
            let response = await this.props.listUsers(options);
            this.setState({ userData: response.payload, defaultSearchData: response.payload });
        } catch (err) {
            console.log("Error While Bill Listing" + err);
        }
    }

    onClear = async () => {
        try {
            this.componentDidMount();
        } catch (err) {
            console.log("Error While Bill Listing" + err);
        }
    }

    handleChange = (e) => {
        try {
            let tid = e.target.id;
            switch (tid) {
                case 'roles':
                    this.setState({ roleId: e.target.value });
                    break;
                case 'active':
                    this.setState({ active: e.target.value });
                    break;
                default:
                    console.log("No matching target found");
                    break;
            }
        } catch (err) {
            console.error(err.message);
        }
    }

    handlePageChange = (pageNumber) => {
        this.setState({ currentPage: pageNumber });
    };

    DefaultSearchChange = (e) => {
        const searchData = e.target.value;
        const defaultSearchData = this.state.userData.filter(data => {
            const valuesToSearch = Object.values(data).filter(value => value !== null);
            return valuesToSearch.some(value =>
                String(value).toLowerCase().includes(searchData.toLowerCase())
            );
        });
        this.setState({ searchData, defaultSearchData });
    };

    handleTableSort = (column) => {
        const { defaultSearchData, sortedColumn, sortOrder } = this.state;
        // Toggle the sortOrder if the same column is clicked again
        const newSortOrder = column === sortedColumn && sortOrder === 'asc' ? 'desc' : 'asc';

        // Sort the data based on the selected column and sortOrder
        const sortedData = defaultSearchData.slice().sort((a, b) => {
            if (newSortOrder === 'asc') {
                return a[column] > b[column] ? 1 : -1;
            } else {
                return a[column] < b[column] ? 1 : -1;
            }
        });

        this.setState({
            defaultSearchData: sortedData,
            sortedColumn: column,
            sortOrder: newSortOrder,
        });
    };

    render() {
        const { role_name, roles, defaultSearchData, currentPage, itemsPerPage, searchData, sortedColumn, sortOrder } = this.state;
        // For Pagination
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentData = defaultSearchData.slice(indexOfFirstItem, indexOfLastItem);
        const totalPages = Math.ceil(defaultSearchData.length / itemsPerPage);
        const inputFontStyle = { fontSize: '10px' };
        return (
            <Fragment>
                <div className='page-title'>
                    <Row>
                        <Col xs='6'>
                            <H3></H3>
                        </Col>
                        <Col xs='6'>
                            <ol className='breadcrumb'>
                                <li className='breadcrumb-item'>
                                    <Link to="/dashboard/">
                                        <SvgIcon iconId='stroke-home' />
                                    </Link>
                                </li>
                                <li className='breadcrumb-item active'>Access Management</li>
                            </ol>
                        </Col>
                    </Row>
                </div>
                <ToastContainer />
                <Container fluid={true}>
                    <Row>
                        <Col sm="12">
                            <Card>
                                <CardHeader className='d-flex align-items-center justify-content-between'>
                                    <H6>Access Management</H6>
                                    {role_name === 'Admin' || role_name === 'System Administrator' ? <span></span> : <span>
                                        <div className='d-flex align-items-center gap-3'>
                                            <Link to="/access-management/add-user/">
                                                <Btn attrBtn={{ color: 'primary d-flex align-items-center' }}>Add New User</Btn>
                                            </Link>
                                        </div>
                                    </span>}
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <Row>
                                            <Col md='2'>
                                                <Input style={inputFontStyle} type="select" name="roles" id="roles" onChange={this.handleChange}>
                                                    <option>Select Role</option>
                                                    {roles.map((role, i) =>
                                                        <option key={i} value={role.id}>{role.role_name}</option>
                                                    )}
                                                </Input>
                                            </Col>
                                            <Col md='2'>
                                                <Input style={inputFontStyle} type="select" name="active" id="active" onChange={this.handleChange}>
                                                    <option value={1}>Active</option>
                                                    <option value={0}>Inactive</option>
                                                </Input>
                                            </Col>
                                            {/* <Col md='1'>
                                                <button type='button' onClick={this.onNewest} style={buttionStyle}>Newest</button>
                                            </Col> */}
                                            <Col md='3' className='mb-2'>
                                                <Button onClick={this.onSearch}>Search</Button>&nbsp;&nbsp;
                                                <Button onClick={this.onClear} type='reset'>Clear</Button>
                                            </Col><Col></Col>
                                            <Col md='3'>
                                                <div className="job-filter">
                                                    <div className="faq-form">
                                                        <Input style={inputFontStyle} type="text" placeholder="Search" value={searchData} onChange={this.DefaultSearchChange} />
                                                        <Search className="search-icon" />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                    <Table ref="tbl" striped hover responsive bordered id="data-table-zero">
                                        <thead>
                                            <tr>
                                                <th onClick={() => this.handleTableSort('name')}>Name {sortedColumn === 'name' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                                                <th onClick={() => this.handleTableSort('user_name')}>User Name {sortedColumn === 'user_name' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                                                <th onClick={() => this.handleTableSort('time_stamp')}>User Created Date {sortedColumn === 'time_stamp' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                                                <th onClick={() => this.handleTableSort('role_id')}>Role {sortedColumn === 'role_id' && <span>{sortOrder === 'asc' ? '↑' : '↓'}</span>}</th>
                                                {role_name === 'Admin' || role_name === 'System Administrator' ? <span style={{"visibility": "hidden"}}></span> : <th>Actions</th>}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentData.map((data, i) =>
                                                <tr key={i}>
                                                    <td>{data.name}</td>
                                                    <td>{data.user_name}</td>
                                                    <td>{formatDateAndTime(data.time_stamp)}</td>
                                                    <td>{getRoleName(data.role_id)}</td>
                                                    {role_name === 'Admin' || role_name === 'System Administrator' ? <span style={{"visibility": "hidden"}}></span> :<td>
                                                        {data.is_active ?
                                                            <span>
                                                                <img src={editIcon} alt='edit' height={17} width={17} data-test-id={data.id} onClick={this.handleEditClick} />&nbsp;
                                                                <img src={pauseIcon} alt='pause' height={16} width={16} data-test-id={data.id} onClick={this.onPauseClick} />
                                                            </span>
                                                            : <img src={undoIcon} alt='undo' height={16} width={16} data-test-id={data.id} onClick={this.onPauseClick}></img>
                                                        }
                                                    </td>}
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </CardBody>
                                <CardFooter>
                                    <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={this.handlePageChange} />
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}
const mapStateToProps = () => { return {} };

const mapDispatchToProps = {
    listUsers: userOperations.listUsers,
    deleteUser: userOperations.deleteUser,
    listRoles: roleOperations.listRoles
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessManagement)