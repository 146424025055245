export const LOGIN = "auth/LOGIN";
export const LOGIN_COMPLETED = "auth/LOGIN_COMPLETED";
export const LOGIN_FAILED = "auth/LOGIN_FAILED";
export const LOGOUT = "auth/LOGOUT";
export const AUTHENTICATED = "auth/AUTHENTICATED";
export const INITIALIZE = "auth/INITIALIZE_SESSION";
export const DESTROY = "auth/DESTROY_SESSION";
export const AUTHORIZATION_FAILED = "auth/AUTHORIZATION_FAILED";
export const SET_REDIRECT_AFTER_LOGIN = "auth/SET_REDIRECT_AFTER_LOGIN";

export const FORGOT_PASSWORD = "auth/FORGOT_PASSWORD";
export const FORGOT_PASSWORD_COMPLETED = "auth/FORGOT_PASSWORD_COMPLETED";
export const FORGOT_PASSWORD_FAILED = "auth/FORGOT_PASSWORD_FAILED";

export const RESET_PASSWORD = "auth/RESET_PASSWORD";
export const RESET_PASSWORD_COMPLETED = "auth/RESET_PASSWORD_COMPLETED";
export const RESET_PASSWORD_FAILED = "auth/RESET_PASSWORD_FAILED";

export const CHANGE_PASSWORD = "auth/CHANGE_PASSWORD";
export const CHANGE_PASSWORD_COMPLETED = "auth/CHANGE_PASSWORD_COMPLETED";
export const CHANGE_PASSWORD_FAILED = "auth/CHANGE_PASSWORD_FAILED";

export const CONFIRM_OTP = "auth/CONFIRM_OTP";
export const CONFIRM_OTP_COMPLETED = "auth/CONFIRM_OTP_COMPLETED";
export const CONFIRM_OTP_FAILED = "auth/CONFIRM_OTP_FAILED";

export const INSERT = "auth/INSERT";
export const INSERT_COMPLETED = "auth/INSERT_COMPLETED";
export const INSERT_FAILED = "auth/INSERT_FAILED";

export const FETCH_MENU_LIST = "auth/FETCH_MENU_LIST";
export const FETCH_MENU_LIST_COMPLETED = "auth/FETCH_MENU_LIST_COMPLETED";
export const FETCH_MENU_LIST_FAILED = "auth/FETCH_MENU_LIST_FAILED";